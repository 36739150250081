@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.header {
  height: f.size(50);
  padding: f.size(10) f.size(20);
  img {
    width: f.size(85);
  }
}
.contact-page {
  margin-bottom: f.size(30);
  &__ttl {
    text-align: center;
    h1 {
      font-size: f.size(45);
      color: f.primary();
      font-weight: bold;
      text-transform: uppercase;
      @include m.lineHeight(70, 45);
    }
    p {
      font-size: f.size(18);
      font-weight: bold;
      line-height: 1;
    }
  }
  &__message {
    margin-top: f.size(9);
    padding: f.size(20);
    background-color: #ffebe7;
    p {
      font-size: f.size(14);
      color: #434343;
      @include m.lineHeight(26, 14);
    }
    &.thanks {
      margin-bottom: f.size(40);
      padding: f.size(26) f.size(18);
      text-align: center;
      border-radius: f.size(10);
    }
  }
  &.thanks {
    margin: 0 f.size(20) f.size(30);
    .contact__btn.back {
      background-color: #fff;
      border: 1px solid #707070;
    }
  }
  .page__ttl__ja {
    color: #606060;
  }
  &__form {
    margin: f.size(26) f.size(20) 0;
    input,
    select {
      padding: f.size(4) f.size(8);
      width: 100%;
      height: f.size(50);
      border: 1px solid #707070;
      outline: none;
      &::placeholder {
        color: #bfbfbf;
      }
      &.contact__shop {
        width: f.size(274);
        height: f.size(41);
        margin-left: f.size(5);
      }
    }
    input {
      padding: f.size(14) f.size(16);
    }
    input[type='checkbox'] {
      display: none;
      &:checked + span::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: f.size(22);
        height: f.size(22);
        background: url(../img/checked.png) no-repeat;
        background-size: 70%;
        background-position: center;
      }
    }
    input[type='radio'] {
      display: none;
      &:checked + span::after {
        opacity: 1;
      }
    }
    textarea {
      padding: f.size(11) f.size(16);
      width: 100%;
      border: 1px solid #707070;
    }
    dt {
      margin-top: f.size(22);
      font-size: f.size(16);
      font-weight: bold;
      color: #606060;
    }
    dd {
      margin-top: f.size(8);
      &.mt-m {
        margin-top: f.size(16);
      }
    }
    &.confirm {
      .contact__select {
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }
      }
    }
  }
  .contact {
    &__select {
      display: inline-block;
      position: relative;
      &.--wide {
        width: 100%;
      }
      &::before {
        content: '';
        height: calc(100% - 2px);
        width: f.size(28);
        background-color: #ffebe7;
        position: absolute;
        top: 1px;
        right: 1px;
        z-index: -1;
      }
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-right: f.size(6) solid transparent;
        border-left: f.size(6) solid transparent;
        border-top: f.size(9) solid #a7a7a7;
        border-bottom: 0;
        position: absolute;
        top: 50%;
        right: f.size(8);
        transform: translateY(-50%);
        z-index: -1;
      }
    }
    &__birth {
      display: flex;
      gap: f.size(20);
      select {
        width: f.size(110);
        height: f.size(35);
      }
      .contact__select:nth-of-type(n + 2) select {
        width: f.size(93);
      }
    }
    &__contact {
      & + span {
        margin-right: f.size(15);
      }
    }
    &__checkbox {
      height: f.size(22);
      width: f.size(22);
      position: relative;
      & + span {
        position: relative;
        padding-left: f.size(26);
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: f.size(22);
          height: f.size(22);
          display: inline-block;
          border: 1px solid #404040;
        }
      }
    }
    &__works {
      height: f.size(35);
      width: f.size(168);
    }
    &__area {
      margin-left: f.size(5);
      height: f.size(35);
      width: f.size(113);
    }
    &__shopname {
      margin-left: f.size(5);
      height: f.size(35);
      width: f.size(280);
    }
    &__experience {
      height: f.size(23);
      width: f.size(23);
      position: relative;
      & + span {
        margin-right: f.size(36);
        position: relative;
        padding-left: f.size(26);
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: f.size(23);
          width: f.size(23);
          display: inline-block;
          border-radius: 50%;
          border: 1px solid #707070;
        }
        &::after {
          content: '';
          position: absolute;
          top: f.size(4);
          left: f.size(4);
          display: block;
          width: f.size(15);
          height: f.size(15);
          border-radius: 50%;
          background-color: #707070;
          opacity: 0;
        }
      }
    }
    &__interview {
      select {
        height: f.size(35);
        width: f.size(93);
      }
      .contact__select:last-of-type {
        select {
          margin-left: f.size(20);
        }
      }
    }
    &__center {
      text-align: center;
    }
    &__privacy {
      font-size: f.size(12);
      color: #606060;
      margin-top: f.size(10);
      padding: f.size(5) f.size(16) f.size(5) f.size(7);
      height: f.size(147);
      overflow-y: auto;
      border: 1px solid #707070;
      &-check {
        margin-top: f.size(17);
        text-align: center;
        input[name='privacy[data][]'] {
          height: f.size(22);
          width: f.size(22);
        }
        a {
          text-decoration: underline;
          margin-left: f.size(9);
          font-size: f.size(15);
        }
      }
      p {
        font-size: f.size(12);
      }
    }
    &__btn {
      margin-top: f.size(27);
      height: f.size(56);
      width: 100%;
      border-radius: f.size(50);
      font-size: f.size(20);
      font-weight: 500;
      text-align: center;
      color: #fff;
      transition: opacity 0.3s;
      &.confirm {
        background-image: linear-gradient(
          135deg,
          rgba(255, 130, 169, 1),
          rgba(255, 192, 190, 1)
        );
      }
      &.submit {
        background-image: linear-gradient(
          135deg,
          rgba(255, 130, 169, 1),
          rgba(255, 192, 190, 1)
        );
      }
      &.back {
        margin-top: f.size(15);
        color: #404040;
        background-color: #f0f0f0;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: f.size(20);
        }
      }
      &:hover {
        opacity: 0.5;
      }
    }
    &__note {
      color: #606060;
      font-size: f.size(12);
      &-red {
        color: #ff0000;
        font-size: f.size(12);
        font-weight: bold;
      }
    }
    &__required {
      display: inline-block;
      padding-right: f.size(45);
      position: relative;
      &::after {
        position: absolute;
        right: 0;
        content: '必須';
        height: f.size(22);
        width: f.size(40);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: f.size(12);
        color: #fff;
        background-color: #be0000;
        border-radius: f.size(80);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &.confirm {
    .contact {
      &__select {
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }
      }
      &__privacy-check {
        input[type='checkbox'] {
          display: none;
        }
      }
    }
  }
  .error {
    display: block;
    font-size: f.size(14);
    color: #be0000;
  }
}
.mw_wp_form .error {
  position: absolute;
}
/* -- responsive ---------------------------- */
/* ---------------------------- responsive -- */

@include m.media(for-pc) {
  .header {
    height: f.size(80, 1400);
    padding: f.size(15, 1400) f.size(150, 1400);
    img {
      width: f.size(130, 1400);
    }
  }
  .contact-page {
    margin: f.size(36, 1400) f.size(150, 1400) f.size(40, 1400);
    &__ttl {
      h1 {
        font-size: f.size(45, 1400);
      }
      p {
        font-size: f.size(18, 1400);
      }
    }
    &__message {
      margin-top: f.size(29, 1400);
      padding: f.size(34, 1400) f.size(72, 1400) f.size(40, 1400);
      text-align: center;
      p {
        font-size: f.size(14, 1400);
      }
      &.confirm {
        text-align: left;
      }
      &.thanks {
        margin-bottom: f.size(33, 1400);
        padding: f.size(28, 1400) f.size(20, 1400) f.size(46, 1400);
        text-align: center;
        border-radius: 0;
        p {
          font-size: f.size(16, 1400);
        }
      }
    }
    &.thanks {
      margin: 0 f.size(150, 1400) f.size(30, 1400);
      .contact__btn.back {
        width: f.size(435, 1400);
      }
    }
    .page__ttl__ja {
      color: #606060;
    }
    &__form {
      margin: f.size(46, 1400) f.size(110, 1400) 0;
      input,
      select {
        padding: f.size(4, 1400) f.size(8, 1400);
        height: f.size(58, 1400);
        &.contact__shop {
          width: f.size(323, 1400);
          height: f.size(41, 1440);
          margin-left: f.size(5, 1400);
        }
      }
      input {
        padding: f.size(14, 1400) f.size(16, 1400);
      }
      input[type='checkbox'] {
        &:checked + span::before {
          width: f.size(25, 1400);
          height: f.size(25, 1400);
        }
      }
      textarea {
        height: f.size(536, 1400);
        padding: f.size(11, 1400) f.size(16, 1400);
      }
      dt {
        margin-top: f.size(22, 1400);
        font-size: f.size(18, 1400);
      }
      dd {
        margin-top: f.size(10, 1400);
        &.mt-m {
          margin-top: f.size(16, 1400);
        }
      }
    }
    .contact {
      &__select {
        &::before {
          height: calc(100% - 2px);
          width: f.size(33, 1400);
        }
        &::after {
          border-right: f.size(6, 1400) solid transparent;
          border-left: f.size(6, 1400) solid transparent;
          border-top: f.size(9, 1400) solid #a7a7a7;
          right: f.size(11, 1400);
        }
      }
      &__birth {
        gap: f.size(17, 1400);
        select {
          width: f.size(127, 1400);
          height: f.size(40, 1400);
        }
        .contact__select:nth-of-type(n + 2) select {
          width: f.size(107, 1400);
        }
      }
      &__contact {
        & + span {
          margin-right: f.size(20, 1400);
        }
      }
      &__checkbox {
        height: f.size(25, 1400);
        width: f.size(25, 1400);
        & + span {
          padding-left: f.size(30, 1400);
          &::before {
            width: f.size(25, 1400);
            height: f.size(25, 1400);
          }
        }
      }
      &__works {
        height: f.size(41, 1400);
        width: f.size(193, 1400);
      }
      &__area {
        margin-left: f.size(5, 1400);
        height: f.size(40, 1400);
        width: f.size(130, 1400);
      }
      &__shopname {
        margin-top: f.size(6, 1400);
        margin-left: f.size(5, 1400);
        height: f.size(40, 1400);
        width: f.size(323, 1400);
      }
      &__experience {
        height: f.size(25, 1400);
        width: f.size(25, 1400);
        position: relative;
        & + span {
          margin-right: f.size(20, 1400);
          padding-left: f.size(30, 1400);
          &::before {
            height: f.size(25, 1400);
            width: f.size(25, 1400);
            top: 50%;
            transform: translateY(-50%);
          }
          &::after {
            top: 50%;
            transform: translateY(-50%);
            left: 8%;
            width: f.size(17, 1400);
            height: f.size(17, 1400);
          }
        }
      }
      &__interview {
        select {
          height: f.size(35, 1400);
          width: f.size(93, 1400);
        }
        .contact__select {
          width: f.size(334, 1400);
        }
        .contact__select:last-of-type {
          select {
            margin-left: f.size(11, 1400);
          }
        }
      }
      &__privacy {
        font-size: f.size(12, 1400);
        margin-top: f.size(13, 1400);
        padding: f.size(5, 1400) f.size(19, 1400) f.size(5, 1400)
          f.size(8, 1400);
        height: f.size(169, 1400);
        &-check {
          margin-top: f.size(20, 1400);
          input[name='privacy[data][]'] {
            height: f.size(25, 1400);
            width: f.size(25, 1400);
          }
          a {
            margin-left: f.size(10, 1400);
            font-size: f.size(15, 1400);
          }
        }
        p {
          font-size: f.size(12, 1400);
        }
      }
      &__btn {
        margin-top: f.size(30, 1400);
        height: f.size(65, 1400);
        width: f.size(437, 1400);
        border-radius: f.size(50, 1400);
        font-size: f.size(20, 1400);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        &.back {
          margin-top: f.size(18, 1400);
          a {
            font-size: f.size(20, 1400);
          }
        }
      }
      &__note {
        font-size: f.size(14, 1400);
        &-red {
          font-size: f.size(14, 1400);
        }
      }
      &__required {
        padding-right: f.size(57, 1400);
        &::after {
          height: f.size(25, 1400);
          width: f.size(46, 1400);
          font-size: f.size(14, 1400);
          border-radius: f.size(80, 1400);
        }
      }
    }
    .error {
      font-size: f.size(14, 1400);
    }
  }
}
