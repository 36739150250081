@mixin letterSpace($w) {
  letter-spacing: calc($w / 1000) * 1em;
}
@mixin lineHeight($h, $f) {
  line-height: calc($h / $f);
}
@mixin reader($num) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $num;
  overflow: hidden;
}
@mixin over {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
}
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

$breakpoints: (
  for-sp: 'only screen and (max-width: 767px)',
  for-pc: 'only screen and (min-width: 768px)',
  sp: 'only screen and (max-width: 425px)',
  tab: 'only screen and (max-width: 768px)',
  tab-l: 'only screen and (max-width: 1366px)',
);
@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
