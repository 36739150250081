@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.notpage {
  margin: f.size(65) f.size(20) f.size(28);
  &__ttl {
    text-align: center;
    h1 {
      font-size: f.size(36);
      font-weight: bold;
    }
  }
  &__message {
    margin-bottom: f.size(30);
    margin-top: f.size(19);
    padding: f.size(28) f.size(15) f.size(37) f.size(19);
    background-color: #f8f8f8;
    border-radius: f.size(10);
    p {
      font-size: f.size(15);
      text-align: center;
    }
  }
}

.privacy {
  margin: f.size(48) f.size(20) f.size(28);
  h1 {
    font-size: f.size(18);
    font-weight: bold;
    text-align: center;
  }
  &__txt {
    margin-top: f.size(5);
    margin-bottom: f.size(35);
    color: #606060;
    font-size: f.size(15);
    @include m.lineHeight(28, 15);
    word-break: break-all;
  }
}

@include m.media(for-pc) {
  .notpage {
    margin: f.size(46, 1400) f.size(150, 1400) f.size(280, 1400);
    &__ttl {
      h1 {
        font-size: f.size(36, 1400);
      }
    }
    &__message {
      margin-bottom: 0;
      margin-top: f.size(106, 1400);
      padding: f.size(28, 1400) f.size(71, 1400) f.size(42, 1400);
      border-radius: 0;
      p {
        font-size: f.size(16, 1400);
      }
    }
  }

  .privacy {
    margin: f.size(48, 1400) f.size(265, 1400) f.size(40, 1400);
    h1 {
      font-size: f.size(20, 1400);
    }
    &__txt {
      margin-top: f.size(20, 1400);
      margin-bottom: f.size(35, 1400);
      font-size: f.size(15, 1400);
    }
  }
}
