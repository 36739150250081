@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

/* -- topCatch ---------------------------- */
.topCatch {
  padding: f.size(7) f.size(13);
  background-color: f.primary();
  height: f.size(61);
  &__txt {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    &::before,
    &::after {
      content: '';
      height: 90%;
      width: f.size(2);
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      top: 50%;
    }
    &::before {
      transform-origin: top left;
      left: 0;
      transform: translateY(-50%) rotate(-17deg);
    }
    &::after {
      transform-origin: top right;
      right: 0;
      transform: translateY(-50%) rotate(17deg);
    }
    &--first {
      font-size: f.size(14);
      line-height: 1;
    }
    &--second {
      font-size: f.size(20);
      span {
        color: #fff;
        font-weight: bold;
        font-size: f.size(24);
        position: relative;
        &::after {
          content: '';
          height: f.size(4);
          width: f.size(4);
          background-color: #fff8a4;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
/* ---------------------------- topCatch -- */

/* -- fv ---------------------------- */
.fv {
  position: relative;
  &__img {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  h1 {
    font-size: f.size(38);
    font-weight: bold;
    text-align: center;
    position: relative;
    @include m.lineHeight(56, 38);
    &::after {
      content: attr(data-ja);
      font-size: f.size(12);
      font-weight: bold;
      position: absolute;
      bottom: calc(-1 * f.size(5));
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__catch--wrap {
    display: flex;
    justify-content: center;
    p {
      font-weight: bold;
      text-align: center;
      font-size: f.size(15);
      span {
        font-size: f.size(18);
        font-weight: bold;
        color: f.primary();
      }
      & + p {
        filter: drop-shadow(-1px 2px 0 rgba(#ffffff, 100%));
      }
    }
  }
  &__feature {
    width: 100vw;
    padding: f.size(6) 0 f.size(8);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #f2f2f2;
      z-index: -2;
    }
    div {
      display: flex;
      align-items: center;
      p {
        font-size: f.size(15);
        font-weight: bold;
      }
      img:nth-of-type(1) {
        width: f.size(30);
        margin-right: f.size(7);
      }
      img:nth-of-type(2) {
        width: f.size(22);
        margin-left: f.size(6);
      }
      & + p {
        font-size: f.size(15);
        font-weight: bold;
        line-height: 1;
        margin-left: 4vw;
      }
    }
  }
  &__content {
    margin-top: f.size(100);
    &--rank {
      width: f.size(177);
      margin-left: auto;
      margin-right: 4vw;
    }
    &--point {
      margin: f.size(5) f.size(14) 0;
      display: flex;
      justify-content: space-between;
      &--wrap {
        height: f.size(115);
        width: calc(100% / 3);
        background: url('../img/point_bg.png') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-weight: bold;
        p {
          margin-top: f.size(18);
          text-align: center;
          font-size: f.size(12);
          &::first-letter {
            text-transform: uppercase;
          }
          & + p {
            margin-top: f.size(4);
            @include m.lineHeight(20, 15);
            filter: drop-shadow(1px 1px 1px rgba(#000, 30%));
            position: relative;
            font-size: f.size(15);
          }
        }
        &:nth-last-of-type(2) {
          background: url('../img/point_bg_reverse.png') no-repeat;
          background-size: cover;
        }
      }
    }
    &--pay {
      margin-top: calc(-1 * f.size(62));
      background-color: #ffebe7;
      z-index: -1;
      padding: f.size(73) f.size(20) f.size(10);
      display: flex;
      justify-content: space-between;
      gap: f.size(8);
      &--wrap {
        text-align: center;
        &:nth-of-type(1) {
          width: f.size(104);
        }
        &:nth-of-type(2) {
          width: f.size(104);
        }
        &:nth-of-type(3) {
          width: f.size(110);
        }
      }
      &--ttl {
        padding: f.size(7) 0;
        line-height: 1;
        background-color: #fff;
        border-radius: f.size(14);
        filter: drop-shadow(0 2px 2px rgba(#000, 16%));
        font-size: f.size(12);
        font-weight: bold;
      }
      &--num {
        margin-top: f.size(8);
        font-size: f.size(34);
        color: f.primary();
        font-weight: bold;
        // font-family: 'Noto Sans JP', sans-serif;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .big {
          color: inherit;
          font-weight: bold;
          font-size: f.size(28);
          font-family: inherit;
        }
        .small {
          color: inherit;
          font-weight: bold;
          font-family: inherit;
          font-size: f.size(12);
          align-self: flex-end;
          margin-bottom: f.size(4);
        }
      }
    }
  }
}
/* ---------------------------- fv -- */

/* -- worries ---------------------------- */
.worries {
  &__ttl {
    background-color: #f5f5f5;
    filter: drop-shadow(0 3px 2px rgba(#000, 16%));
    position: relative;
    padding: f.size(10) 0 f.size(15);
    text-align: center;
    font-size: f.size(18);
    font-weight: bold;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-top: 25px solid #f5f5f5;
      border-bottom: 0;
      position: absolute;
      bottom: calc(-1 * f.size(15));
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__content {
    margin-top: f.size(14);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: f.size(7);
    img {
      height: f.size(113);
      width: f.size(113);
    }
  }
  &__txt {
    display: grid;
    gap: f.size(15);
    position: relative;
    &::after {
      content: attr(data-other);
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: f.size(15);
      font-weight: bold;
    }
    p {
      font-size: f.size(15);
      font-weight: bold;
      position: relative;
      text-decoration-color: #ffff3c;
      text-decoration: underline;
      text-decoration-thickness: f.size(5);
      &::after {
        content: '';
        height: f.size(5);
        width: 100%;
        background-color: #ffff3c;
      }
    }
  }
}

/* ---------------------------- worries -- */

/* -- merit ---------------------------- */
.merit {
  margin-top: f.size(18);
  padding: f.size(17) 0 f.size(13);
  background-color: f.primary();
  &__catch {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: f.size(6);
    p {
      display: inline-block;
      background-color: #fff;
      color: f.primary();
      font-size: f.size(19);
      font-weight: bold;
      span {
        font-size: f.size(24);
        color: inherit;
        font-weight: inherit;
      }
    }
  }
  &__wrap {
    margin-top: f.size(12);
    img {
      height: f.size(183);
    }
  }
  &__content {
    margin-top: f.size(15);
  }
  &__list {
    display: flex;
    justify-content: center;
    gap: f.size(4);
    li {
      background-color: #fff;
      box-shadow: 1px 1px 2px rgba(#000, 16%);
      font-size: f.size(14);
      font-weight: bold;
      text-align: center;
      color: #606060;
      &:nth-of-type(1),
      &:nth-of-type(3) {
        width: f.size(88);
      }
      &:nth-of-type(2) {
        width: f.size(106);
      }
      &:nth-of-type(4) {
        font-size: f.size(12);
        font-weight: normal;
        background-color: unset;
        align-self: flex-end;
        line-height: 1;
        box-shadow: none;
      }
    }
  }
  &__txt {
    margin-top: f.size(8);
    p {
      font-size: f.size(24);
      font-weight: bold;
      text-align: center;
      color: #fff;
      span {
        font-size: f.size(21);
        color: inherit;
      }
      & + p {
        font-size: f.size(26);
      }
    }
  }
}
/* ---------------------------- merit -- */

/* -- form ---------------------------- */
.form {
  padding: f.size(18) f.size(20) f.size(15);
  background-image: linear-gradient(
    180deg,
    rgba(255, 192, 190, 1),
    rgba(255, 235, 231, 1)
  );
  p {
    margin-top: f.size(10);
    text-align: center;
    font-weight: 500;
    font-size: f.size(15);
  }
  &__btn {
    margin-top: f.size(10);
    button {
      width: 100%;
      height: f.size(64);
      border: 3px solid #434343;
      border-radius: f.size(10);
      background-color: #fff;
      filter: drop-shadow(0 3px 6px rgba(#000, 16%));
      a {
        display: flex;
        align-items: center;
        height: 100%;
        gap: f.size(8);
        font-size: f.size(22);
        font-weight: bold;
        position: relative;
        padding: 0 f.size(13) 0 f.size(17);
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 11px solid #434343;
          border-right: 0;
          position: absolute;
          top: 53%;
          right: f.size(13);
          transform: translateY(-50%);
        }
        img {
          width: f.size(31);
          height: auto;
          // height: f.size(28);
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
    &.fixed_sp {
      position: fixed;
      bottom: f.size(20);
      width: f.size(335);
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
      transition: 0.3s;
      z-index: 500;
      &.hidden {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__link {
    margin-top: f.size(15);
    font-size: f.size(14);
    text-decoration: underline;
    text-underline-offset: 2px;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
}
/* ---------------------------- form -- */

/* -- point ---------------------------- */
.point {
  margin-top: f.size(11);
  margin-bottom: f.size(13);
  &__ttl {
    font-size: f.size(36);
    color: f.primary();
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  &__catch {
    font-size: f.size(18);
    text-align: center;
    font-weight: bold;
    span {
      font-size: f.size(32);
      color: f.primary();
    }
  }
  &__wrap {
    margin-top: f.size(54);
    position: relative;
    &:nth-of-type(n + 2) {
      margin-top: f.size(63);
    }
    &--imgTtl {
      position: absolute;
      top: calc(-1 * f.size(31));
      left: f.size(20);
      width: f.size(135);
    }
    &--ttl {
      padding: f.size(10) 0 f.size(8);
      background-color: f.primary();
      font-size: f.size(24);
      color: #fff;
      text-align: center;
      font-weight: bold;
      span {
        font-size: f.size(20);
        color: inherit;
      }
    }
    &.bg_gray {
      background-color: #f5f5f5;
    }
  }
  &__inner {
    & + img {
      height: f.size(142);
    }
  }
  &__txt {
    margin: 0 f.size(20);
    p {
      color: #606060;
      font-size: f.size(14);
      margin-top: f.size(20);
      @include m.lineHeight(28, 14);
      span {
        color: f.primary();
        font-weight: bold;
        font-size: inherit;
      }
    }
  }
  &__pay {
    margin: f.size(20) f.size(20) 0;
    &--note {
      color: #606060;
      font-size: f.size(14);
      @include m.lineHeight(28, 14);
    }
    &--wrap {
      margin-top: f.size(20);
      &:nth-of-type(n + 2) {
        margin-top: f.size(8);
      }
    }
    &--ttl {
      height: f.size(32);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: f.size(14);
      color: #fff;
      font-weight: bold;
      &.office {
        background-color: #ffc0be;
      }
      &.house {
        background-color: f.primary();
      }
    }
    &--detail {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: f.size(18);
    }
    &--txt {
      margin-top: f.size(8);
      p {
        text-align: center;
        color: #606060;
        &:first-of-type {
          font-size: f.size(14);
          padding-bottom: f.size(10);
          border-bottom: 1px dashed #606060;
        }
        &:last-of-type {
          font-weight: bold;
          font-size: f.size(46);
          span {
            color: inherit;
            font-weight: inherit;
            font-size: f.size(20);
          }
        }
      }
    }
  }
  &__demand {
    margin: f.size(18) f.size(20) 0;
    &--ttl {
      text-align: center;
      position: relative;
      padding: f.size(8) 0;
      font-size: f.size(14);
      font-weight: normal;
      background-color: #fff;
      color: #606060;
      &::before,
      &::after {
        content: '';
        width: 100%;
        height: f.size(4);
        border-top: 1px solid #606060;
        border-bottom: 1px solid #606060;
        position: absolute;
        background-color: #f5f5f5;
        left: 50%;
        transform: translateX(-50%);
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }
    &--list {
      margin-top: f.size(10);
      li {
        color: #606060;
        line-height: 2;
        padding-left: f.size(20);
        position: relative;
        font-size: f.size(14);
        &::before {
          content: '';
          height: f.size(8);
          width: f.size(8);
          border-radius: 50%;
          background-color: #434343;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  &__ease {
    margin: f.size(13) f.size(20) 0;
    &--ttl {
      font-size: f.size(18);
      font-weight: bold;
      color: #fff;
      height: f.size(46);
      background-color: f.primary();
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: f.size(50);
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-top: 25px solid f.primary();
        border-bottom: 0;
        position: absolute;
        bottom: calc(-1 * f.size(12));
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &--content {
      margin-top: f.size(17);
      padding-bottom: f.size(16);
      &--wrap {
        margin-bottom: f.size(14);
        &:nth-of-type(n + 2) {
          padding-top: f.size(13);
          border-top: 1px dashed #707070;
        }
      }
      &--ttl {
        display: flex;
        align-items: center;
        gap: f.size(8);
        font-size: f.size(18);
        font-weight: bold;
        img {
          height: f.size(70);
          width: f.size(70);
        }
      }
      &--txt {
        font-size: f.size(14);
        line-height: 2;
        span {
          color: f.primary();
          font-weight: bold;
          font-size: inherit;
        }
      }
    }
  }
}
/* ---------------------------- point -- */

/* -- anxiety ---------------------------- */
.anxiety {
  margin: f.size(20) f.size(20) f.size(18);
  &__ttl {
    font-size: f.size(20);
    font-weight: bold;
    text-align: center;
  }
  &__wrap {
    margin-top: f.size(20);
  }
}
/* ---------------------------- anxiety -- */

/* -- works ---------------------------- */
.works {
  padding: f.size(11) 0 f.size(33);
  background-color: #f5f5f5;
  &__ttl {
    font-size: f.size(36);
    font-weight: bold;
    text-transform: uppercase;
    color: #cbcbcb;
    text-align: center;
  }
  &__catch {
    font-size: f.size(18);
    font-weight: bold;
    text-align: center;
    span {
      font-size: f.size(32);
      font-weight: bold;
      color: f.primary();
    }
  }
  &__style {
    margin: f.size(9) f.size(20) 0;
    display: flex;
    justify-content: space-between;
    &--wrap {
      width: f.size(157);
      height: f.size(81);
      border-radius: f.size(10);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      &.office {
        background-color: #ffc0be;
        img {
          width: f.size(21);
        }
      }
      &.home {
        background-color: f.primary();
        img {
          width: f.size(31);
        }
      }
      p {
        font-size: f.size(14);
        font-weight: bold;
        color: #fff;
        @include m.lineHeight(20, 14);
      }
    }
    &--rate {
      font-size: f.size(46);
      font-weight: bold;
      text-align: center;
      &.office {
        color: #ffc0be;
      }
      &.home {
        color: f.primary();
      }
      span {
        font-size: f.size(18);
        font-weight: bold;
        color: inherit;
      }
    }
  }
  &__img {
    background: url('../img/works.jpg') no-repeat;
    background-size: cover;
    height: f.size(142);
    position: relative;
    &--bubble {
      width: f.size(98);
      position: absolute;
      &.office {
        bottom: f.size(13);
        left: f.size(20);
      }
      &.home {
        top: f.size(7);
        right: f.size(25);
      }
    }
  }
  &__kinds {
    margin-top: f.size(17);
    &--ttl {
      font-size: f.size(18);
      font-weight: bold;
      text-align: center;
      color: #606060;
    }
    &--wrap {
      margin-top: f.size(61);
      padding: f.size(14);
      background-color: #fff;
      position: relative;
      &:nth-of-type(odd) {
        margin-right: f.size(20);
        padding-left: 0;
        border-radius: 0 f.size(5) f.size(5) 0;
        .works__kinds--name {
          left: f.size(20);
        }
        .works__kinds--txt {
          padding-left: f.size(20);
        }
        .works__kinds--detail {
          padding-left: f.size(20);
        }
      }
      &:nth-of-type(even) {
        margin-left: f.size(20);
        padding-right: 0;
        border-radius: f.size(5) 0 0 f.size(5);
        .works__kinds--name {
          right: f.size(20);
        }
        .works__kinds--txt {
          padding-right: f.size(20);
        }
        .works__kinds--detail {
          padding-right: f.size(20);
        }
      }
      &:nth-of-type(n + 2) {
        margin-top: f.size(65);
      }
    }
    &--name {
      background: url('../img/ribon2.png') no-repeat;
      background-size: cover;
      height: f.size(60);
      width: f.size(335);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: f.size(24);
      font-weight: bold;
      color: f.primary();
      position: absolute;
      top: calc(-1 * f.size(50));
    }
    &--inner {
      img {
        height: f.size(121);
        width: f.size(341);
      }
    }
    &--txt {
      margin-top: f.size(10);
      font-size: f.size(14);
      @include m.lineHeight(22, 14);
      color: #606060;
      span {
        font-size: inherit;
        color: f.primary();
        font-weight: bold;
      }
    }
    &--detail {
      margin: f.size(14) f.size(14) 0;
      display: grid;
      &.normal {
        gap: f.size(11);
        .works__kinds--detail--sum {
          text-align: left;
        }
      }
      &.special {
        grid-template-columns: repeat(2, 1fr);
        gap: f.size(11) f.size(9);
        .works__kinds--detail--pay,
        .works__kinds--detail--sum {
          font-size: f.size(13);
        }
      }
      &--pay {
        height: f.size(31);
        border-radius: f.size(20);
        font-size: f.size(14);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: f.size(20);
          color: inherit;
          font-weight: bold;
        }
        &.office {
          background-color: #ffc0be;
        }
        &.home {
          background-color: f.primary();
        }
      }
      &--sum {
        font-size: f.size(14);
        color: #606060;
        text-align: center;
        span {
          font-size: f.size(20);
          color: inherit;
          font-weight: bold;
        }
        &.office {
          span {
            color: #ffc0be;
          }
        }
        &.home {
          span {
            color: f.primary();
          }
        }
      }
    }
    &--note {
      margin-top: f.size(14);
      margin-left: f.size(34);
      font-size: f.size(12);
      color: #606060;
    }
  }
}
/* ---------------------------- works -- */

/* -- interview ---------------------------- */
.interview {
  margin-top: f.size(13);
  &__ttl {
    font-size: f.size(36);
    font-weight: bold;
    color: f.primary();
    text-transform: uppercase;
    text-align: center;
    & + p {
      font-size: f.size(18);
      font-weight: bold;
      @include m.lineHeight(26, 18);
      text-align: center;
    }
  }
  &__catch {
    font-size: f.size(28);
    font-weight: bold;
    color: f.primary();
    text-align: center;
  }
  &__content {
    margin-top: f.size(46);
    &--model {
      margin: 0 f.size(20);
      display: flex;
      align-items: center;
      justify-content: space-between;
      &--info {
        color: #606060;
        font-size: f.size(15);
        @include m.lineHeight(22, 15);
      }
      &--detail {
        margin-top: f.size(3);
        font-size: f.size(20);
        font-weight: bold;
        color: f.primary();
        @include m.lineHeight(28, 20);
      }
      &--img {
        width: f.size(178);
        height: f.size(178);
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    &--txt {
      color: #606060;
      margin-top: f.size(21);
      padding: f.size(37) f.size(19) f.size(27) f.size(20);
      font-size: f.size(15);
      @include m.lineHeight(22, 15);
      background-color: #f5f5f5;
      box-shadow: 0 3px 6px rgba(#000, 16%);
      position: relative;
      z-index: -1;
      &::before {
        content: '';
        position: absolute;
        top: calc(-1 * f.size(21));
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 24px solid transparent;
        border-left: 24px solid transparent;
        border-bottom: 42px solid #f5f5f5;
        border-top: 0;
      }
      span {
        font-size: inherit;
        font-weight: bold;
        color: f.primary();
      }
    }
  }
  &__wrap {
    &:nth-of-type(2) {
      margin-top: f.size(30);
      .interview__content {
        &--model {
          flex-direction: row-reverse;
          &--info,
          &--detail {
            text-align: right;
          }
        }
      }
    }
  }
}
/* ---------------------------- interview -- */
/* -- qa ---------------------------- */
.qa {
  margin: f.size(7) f.size(20) f.size(28);
  &__ttl {
    text-align: center;
    h2 {
      font-size: f.size(36);
      color: f.primary();
      font-weight: bold;
      text-transform: uppercase;
    }
    p {
      font-size: f.size(18);
      color: #434343;
      font-weight: bold;
    }
  }
  &__wrap {
    margin-top: f.size(15);
  }
  &__content {
    &:nth-child(n + 2) {
      margin-top: f.size(35);
    }
    &--q {
      display: flex;
      align-items: center;
      gap: f.size(4);
      img {
        height: f.size(91);
        width: f.size(91);
      }
      p {
        font-size: f.size(15);
        font-weight: bold;
        @include m.lineHeight(22, 15);
        span {
          color: #ffc0be;
          font-weight: inherit;
          font-size: inherit;
        }
      }
    }
    &--border {
      margin-top: f.size(14);
      height: f.size(2);
      background-color: #434343;
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-top: 13px solid #434343;
        border-bottom: 0;
        position: absolute;
        bottom: calc(-1 * f.size(12));
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &--a {
      margin-top: f.size(22);
      font-size: f.size(16);
      @include m.lineHeight(28, 16);
      span {
        font-size: f.size(20);
        font-weight: bold;
        color: f.primary();
      }
    }
  }
  .form__btn {
    margin-top: f.size(27);
  }
  .qa__content {
    margin-top: 0;
    padding-bottom: f.size(17);
    &--q {
      gap: f.size(8);
      img {
        width: f.size(70);
        height: f.size(70);
      }
      p {
        font-size: f.size(16);
      }
    }
    &:nth-of-type(n + 2) {
      padding-top: f.size(10);
      border-top: 1px dashed #707070;
    }
  }
}
/* ---------------------------- qa -- */
/* -- footer ---------------------------- */
.footer {
  padding: f.size(17) f.size(20) f.size(30);
  background-color: #f5f5f5;
  &__logo {
    width: f.size(246);
    margin: 0 auto;
  }
  &__sns {
    margin-top: f.size(12);
    display: flex;
    justify-content: center;
    gap: f.size(9);
    a {
      width: f.size(25);
      height: f.size(25);
      border-radius: 50%;
      img {
        height: 100%;
      }
    }
  }
  p {
    margin-top: f.size(20);
    font-size: f.size(10);
    @include m.lineHeight(18, 10);
  }
  &__link {
    margin-top: f.size(14);
    display: flex;
    gap: f.size(19);
    a {
      font-size: f.size(10);
      text-decoration: underline #434343;
    }
  }
}
/* ---------------------------- footer -- */

/* -- office ---------------------------- */
.offices {
  margin-top: f.size(12);
  &__ttl {
    font-size: f.size(36);
    font-weight: bold;
    color: f.primary();
    text-transform: uppercase;
    text-align: center;
    & + p {
      font-size: f.size(18);
      font-weight: bold;
      @include m.lineHeight(26, 18);
      text-align: center;
    }
  }
  &__city {
    margin-top: f.size(3);
    padding: f.vw(15) f.vw(20);
    font-size: f.vw(16);
    font-weight: bold;
    background-color: #ffebe7;
  }
  &__list {
    padding: 0 f.vw(19) f.vw(20) f.vw(20);
    margin-top: f.vw(3);
  }
  h4 {
    font-size: f.vw(15);
    font-weight: 400;
    padding-left: f.vw(17);
    padding: f.vw(13) 0;
    border-bottom: 1px dashed #707070;
    cursor: pointer;
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      right: f.size(13);
      width: 0;
      height: 0;
      transform: translateY(-50%);
      border-top: f.vw(6) solid transparent;
      border-right: 0;
      border-bottom: f.vw(6) solid transparent;
      border-left: f.vw(11) solid #d0d0d0;
      border-style: solid;
      content: '';
    }
    &.open {
      &::after {
        transform: rotate(90deg) translateX(calc(-1 * f.vw(5)));
      }
    }
  }
  &__detail {
    display: none;
    &--inner {
      padding-bottom: f.vw(13);
    }
  }
  &__img {
    margin-top: f.vw(26);
    position: relative;
    .swiper-slide {
      text-align: center;
    }
    img {
      width: f.vw(270);
      height: f.vw(185);
      border-radius: f.vw(10);
    }
  }
  &__txt {
    margin-top: f.vw(5);
    h5 {
      margin-top: f.vw(12);
      padding: f.vw(6) 0;
      font-size: f.vw(14);
      font-weight: bold;
      text-align: center;
      background-color: #ffebe7;
      border-radius: f.vw(40);
    }
  }
  &__info {
    li {
      dl {
        display: flex;
        align-items: baseline;
        gap: f.vw(8);
      }
      & + li {
        margin-top: f.vw(7);
      }
    }
  }
  &__tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: f.vw(83);
    font-size: f.vw(14);
    font-weight: bold;
    padding: f.vw(6) 0;
    border-radius: f.vw(40);
    background-color: #f5f5f5;
  }
  &__appeal {
    margin-top: f.vw(7);
    font-size: f.vw(14);
  }
}

// pager
.swiper-pagination-bullets {
  position: static;
  margin-top: f.vw(10);
}
.swiper-pagination-bullet {
  width: f.vw(12) !important;
  height: f.vw(12) !important;
  margin-left: f.vw(12) !important;
  margin-right: 0 !important;
  border: 2px solid #bebebe;
  background: transparent !important;
  &.swiper-pagination-bullet-active {
    background-color: #d0d0d0 !important;
    border: 2px solid #d0d0d0;
  }
}
.swiper-pagination-bullet:first-child {
  margin: 0 !important;
}

// next,prevボタン
.swiper-button-prev,
.swiper-button-next {
  top: f.vw(90);
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  bottom: 0;
  content: '';
  height: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 0;
}
.swiper-button-prev::after {
  border-bottom: f.vw(8) solid transparent;
  border-right: f.vw(15) solid #ff82a9;
  border-top: f.vw(8) solid transparent;
}
.swiper-button-next::after {
  border-bottom: f.vw(8) solid transparent;
  border-left: f.vw(15) solid #ff82a9;
  border-top: f.vw(8) solid transparent;
}
/* ---------------------------- office -- */

/* -- responsive ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ responsive -- */

@include m.media(for-pc) {
  /* -- topCatch ---------------------------- */
  .topCatch {
    padding: f.size(4, 1400) f.size(260, 1400) 0;
    height: f.size(74, 1400);
    &__txt {
      flex-direction: row;
      align-items: baseline;
      &::before,
      &::after {
        width: f.size(4, 1400);
        top: 60%;
      }
      &--first {
        margin-right: f.size(8, 1400);
        font-size: f.size(21, 1400);
      }
      &--second {
        font-size: f.size(32, 1400);
        span {
          font-size: f.size(38, 1400);
          &::after {
            height: f.size(8, 1400);
            width: f.size(8, 1400);
            bottom: calc(-1 * f.size(3, 1400));
          }
        }
      }
    }
  }
  /* ---------------------------- topCatch -- */

  /* -- fv ---------------------------- */
  .fv {
    height: f.size(626, 1400);

    &__wrap {
      margin-right: f.size(150, 1400);
      margin-left: auto;
      width: f.size(900, 1400);
    }
    h1 {
      font-size: f.size(57, 1400);
      text-align: center;
      position: relative;
      @include m.lineHeight(84, 57);
      &::after {
        font-size: f.size(18, 1400);
        bottom: calc(-1 * f.size(10, 1400));
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__catch--wrap {
      margin-top: f.size(6, 1400);
      gap: f.size(16, 1400);
      justify-content: end;
      padding-right: f.size(27, 1400);
      p {
        font-size: f.size(36, 1400);
        span {
          font-size: f.size(43, 1400);
        }
        & + p {
          filter: drop-shadow(-1px 2px 0 rgba(#ffffff, 100%));
        }
      }
    }
    &__feature {
      margin-top: f.size(4, 1400);
      width: 100%;
      padding: f.size(8, 1400) 0;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      padding-right: f.size(30, 1400);
      div {
        p {
          font-size: f.size(26, 1400);
        }
        img:nth-of-type(1) {
          width: f.size(40, 1400);
          margin-right: f.size(9, 1400);
        }
        img:nth-of-type(2) {
          width: f.size(30, 1400);
          margin-left: f.size(10, 1400);
        }
        & + p {
          font-size: f.size(26, 1400);
          margin-left: f.size(10, 1400);
        }
      }
    }
    &__content {
      margin-top: f.size(4, 1400);
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      gap: 0 f.size(14, 1400);
      &--rank {
        width: f.size(277, 1400);
        margin-left: 0;
        margin-right: 0;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
      &--point {
        margin: 0;
        margin-left: auto;
        justify-content: unset;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        &--wrap {
          width: f.size(180, 1400);
          height: f.size(180, 1400);
          p {
            margin-top: f.size(29, 1400);
            font-size: f.size(19, 1400);
            & + p {
              margin-top: f.size(7, 1400);
              @include m.lineHeight(31, 23);
              font-size: f.size(23, 1400);
            }
          }
        }
      }
      &--pay {
        width: f.size(390, 1400);
        height: f.size(180, 1400);
        margin-top: f.size(28, 1400);
        padding: f.size(15, 1400);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        gap: f.size(4, 1400) f.size(8, 1400);
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        filter: drop-shadow(0 3px 6px rgba(#000, 16%));
        margin-left: auto;
        &--wrap {
          text-align: center;
          &:nth-of-type(1) {
            width: 100%;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
          }
          &:nth-of-type(2) {
            width: 100%;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
          }
          &:nth-of-type(3) {
            width: 100%;
            display: flex;
            grid-column: 1 / 3;
            grid-row: 2 / 3;
            gap: f.size(10, 1400);
            justify-content: center;
            .fv__content--pay--ttl {
              margin-top: f.size(2, 1400);
              width: f.size(170, 1400);
            }
            .fv__content--pay--num {
              margin-top: 0;
              font-size: f.size(47, 1400);
              .small {
                font-size: f.size(20, 1400);
              }
            }
          }
        }
        &--ttl {
          height: f.size(42, 1400);
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: f.size(25);
          font-size: f.size(18, 1400);
        }
        &--num {
          margin-top: f.size(6, 1400);
          font-size: f.size(55, 1400);
          .big {
            font-size: f.size(45, 1400);
          }
          .small {
            font-size: f.size(22, 1400);
            margin-bottom: f.size(6, 1400);
          }
        }
      }
    }
  }
  /* ---------------------------- fv -- */

  /* -- worries ---------------------------- */
  .worries {
    &__ttl {
      padding: f.size(17, 1400) 0;
      font-size: f.size(24, 1400);
      &::after {
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        border-top: 35px solid #f5f5f5;
        bottom: calc(-1 * f.size(20, 1400));
      }
    }
    &__content {
      margin-top: f.size(30, 1400);
      gap: f.size(11, 1400);
      img {
        height: f.size(180, 1400);
        width: f.size(180, 1400);
      }
    }
    &__txt {
      gap: f.size(26, 1400);
      &::after {
        font-size: f.size(23, 1400);
      }
      p {
        font-size: f.size(22, 1400);
        text-decoration-thickness: f.size(5, 1400);
      }
    }
  }

  /* ---------------------------- worries -- */

  /* -- merit ---------------------------- */
  .merit {
    margin-top: f.size(50, 1400);
    padding: f.size(96, 1400) 0 f.size(25, 1400);
    position: relative;
    &__catch {
      gap: f.size(12, 1400);
      position: absolute;
      top: calc(-1 * f.size(21, 1400));
      left: 50%;
      transform: translate(-50%);
      p {
        padding: 0 f.size(4, 1400);
        font-size: f.size(26, 1400);
        span {
          font-size: f.size(33, 1400);
        }
        &:first-of-type {
          box-shadow: 0 3px 6px rgba(#000, 16%);
        }
        &:last-of-type {
          line-height: 1.3;
        }
      }
    }
    &__wrap {
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: f.size(16, 1400);
      img {
        height: f.size(174, 1400);
        width: f.size(357, 1400);
      }
    }
    &__content {
      margin-top: 0;
    }
    &__list {
      display: flex;
      justify-content: center;
      gap: f.size(5, 1400);
      li {
        height: f.size(34, 1400);
        font-size: f.size(14, 1400);
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-of-type(1),
        &:nth-of-type(3) {
          width: f.size(112, 1400);
        }
        &:nth-of-type(2) {
          width: f.size(135, 1400);
        }
        &:nth-of-type(4) {
          font-size: f.size(15, 1400);
          align-self: center;
        }
      }
    }
    &__txt {
      margin-top: f.size(8, 1400);
      p {
        font-size: f.size(30, 1400);
        text-align: left;
        span {
          font-size: f.size(27, 1400);
        }
        & + p {
          font-size: f.size(33, 1400);
        }
      }
    }
  }
  /* ---------------------------- merit -- */

  /* -- form ---------------------------- */
  .form {
    padding: f.size(16, 1400) f.size(20, 1400) f.size(23, 1400);
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: f.size(570, 1400);
    }
    p {
      margin-top: f.size(3, 1400);
      font-size: f.size(16, 1400);
    }
    &__btn {
      margin-top: f.size(11, 1400);
      button {
        width: f.size(656, 1400);
        height: f.size(85, 1400);
        border-radius: f.size(10, 1400);
        transition: 0.3s;
        a {
          justify-content: center;
          gap: f.size(9, 1400);
          font-size: f.size(31, 1400);
          transition: 0.3s;
          &::after {
            border-top: f.size(8, 1400) solid transparent;
            border-bottom: f.size(8, 1400) solid transparent;
            border-left: f.size(15, 1400) solid #434343;
            right: f.size(17, 1400);
            transition: 0.3s;
          }
          img {
            width: f.size(49, 1400);
            // height: f.size(44, 1400);
            transition: 0.3s;
          }
        }
        &:hover {
          border: 3px solid #fff;
          background-color: f.primary();
          a {
            color: #fff;
            opacity: 1;
            &::after {
              border-left: f.size(15, 1400) solid #fff;
            }
          }
          // img {
          //   filter: brightness(0) invert(1);
          // }
        }
      }
      &.fixed_pc {
        margin-top: 0;
        position: fixed;
        top: 0;
        right: 0;
        height: f.size(381, 1400);
        width: f.size(84, 1400);
        opacity: 1;
        transition: 0.3s;
        &.hidden {
          opacity: 0;
          visibility: hidden;
        }
        button {
          border-radius: f.size(10, 1400) 0 0 f.size(10, 1400);
          width: 100%;
          height: 100%;
          a {
            padding: 0;
            width: 100%;
            gap: f.size(9, 1400);
            font-size: f.size(22, 1400);
            writing-mode: vertical-rl;
            justify-content: start;
            padding-top: f.size(43, 1400);
            &::after {
              border-top: f.size(13, 1400) solid #ff82a9;
              border-bottom: 0;
              border-left: f.size(7, 1400) solid transparent;
              border-right: f.size(7, 1400) solid transparent;
              top: unset;
              right: unset;
              bottom: f.size(20, 1400);
              transform: translateX(-50%);
              left: 51%;
            }
            img {
              width: f.size(36, 1400);
              height: auto;
              // height: f.size(33, 1400);
            }
          }
          &:hover {
            a {
              &::after {
                border-top: f.size(13, 1400) solid #fff;
              }
            }
          }
        }
      }
    }
    &__link {
      margin-top: f.size(5, 1400);
      font-size: f.size(15, 1400);
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  /* ---------------------------- form -- */

  /* -- point ---------------------------- */
  .point {
    margin-top: f.size(24, 1400);
    margin-bottom: f.size(40, 1400);
    &__ttl {
      font-size: f.size(46, 1400);
      @include m.lineHeight(60, 46);
    }
    &__catch {
      font-size: f.size(24, 1400);
      span {
        font-size: f.size(40, 1400);
      }
    }
    &__wrap {
      margin-top: f.size(45, 1400);
      position: relative;
      &:nth-of-type(2) {
        margin-top: f.size(115, 1400);
      }
      &:nth-of-type(3) {
        margin-top: f.size(63, 1400);
      }
      &--imgTtl {
        top: calc(-1 * f.size(39, 1400));
        left: f.size(150, 1400);
        width: f.size(172, 1400);
      }
      &--ttl {
        padding: f.size(14, 1400) 0 f.size(17, 1400);
        font-size: f.size(32, 1400);
        span {
          font-size: f.size(28, 1400);
        }
      }
      &.bg_gray {
        background-color: #f5f5f5;
      }
    }
    &__inner {
      margin: f.size(35, 1400) f.size(150, 1400) 0;
      display: flex;
      justify-content: center;
      gap: f.size(23, 1400);
      img {
        height: f.size(206, 1400);
        width: f.size(544, 1400);
      }
      &.reverse {
        flex-direction: row-reverse;
      }
    }
    &__txt {
      margin: 0;
      p {
        font-size: f.size(18, 1400);
        margin-top: 0;
        @include m.lineHeight(32, 18);
      }
    }
    &__pay {
      margin: f.size(27, 1400) f.size(150, 1400) 0;
      padding: f.size(23, 1400) f.size(95, 1400);
      border: 1px solid #707070;
      box-shadow: 0 3px 6px rgba(#000, 16%);
      &--note {
        font-size: f.size(18, 1400);
        text-align: center;
        margin-top: 0;
        @include m.lineHeight(32, 18);
      }
      &--content {
        margin-top: f.size(25, 1400);
        display: flex;
        justify-content: space-between;
        gap: f.size(31, 1400);
      }
      &--wrap {
        width: 100%;
        margin-top: 0;
        gap: f.size(31, 1400);
        &:nth-of-type(n + 2) {
          margin-top: 0;
        }
      }
      &--ttl {
        height: f.size(42, 1400);
        font-size: f.size(18, 1400);
      }
      &--detail {
        gap: f.size(20, 1400);
      }
      &--txt {
        margin-top: f.size(15, 1400);
        p {
          &:first-of-type {
            font-size: f.size(16, 1400);
            padding-bottom: f.size(10, 1400);
          }
          &:last-of-type {
            @include m.lineHeight(80, 60);
            font-size: f.size(60, 1400);
            span {
              font-size: f.size(26, 1400);
            }
          }
        }
      }
    }
    &__demand {
      width: f.size(620, 1400);
      margin: 0 auto;
      margin-top: f.size(24, 1400);
      &--ttl {
        padding: f.size(10, 1400) 0;
        font-size: f.size(18, 1400);
        &::before,
        &::after {
          height: f.size(5, 1400);
        }
      }
      &--list {
        margin-top: f.size(18, 1400);
        display: flex;
        justify-content: space-between;
        li {
          padding-left: f.size(19, 1400);
          font-size: f.size(16, 1400);
          &::before {
            height: f.size(8, 1400);
            width: f.size(8, 1400);
          }
        }
      }
    }
    &__ease {
      margin: f.size(28, 1400) f.size(150, 1400) 0;
      &--ttl {
        font-size: f.size(18, 1400);
        height: f.size(46, 1400);
        border-radius: f.size(50, 1400);
        &::after {
          border-right: f.size(13, 1400) solid transparent;
          border-left: f.size(13, 1400) solid transparent;
          border-top: f.size(26, 1400) solid f.primary();
          bottom: calc(-1 * f.size(12, 1400));
        }
      }
      &--content {
        margin: f.size(23, 1400) f.size(26, 1400) 0;
        padding-bottom: f.size(34, 1400);
        display: flex;
        &--wrap {
          margin-bottom: 0;
          padding-right: f.size(10, 1400);
          &:nth-of-type(1) {
            width: f.size(335, 1400);
          }
          &:nth-of-type(n + 2) {
            width: f.size(355, 1400);
            padding-top: 0;
            border-left: 1px dashed #707070;
            padding-left: f.size(20, 1400);
            border-top: unset;
          }
        }
        &--ttl {
          gap: f.size(14, 1400);
          font-size: f.size(18, 1400);
          img {
            height: f.size(64, 1400);
            width: f.size(64, 1400);
          }
        }
        &--txt {
          font-size: f.size(16, 1400);
          @include m.lineHeight(28, 16);
        }
      }
    }
  }
  /* ---------------------------- point -- */

  /* -- anxiety ---------------------------- */
  .anxiety {
    margin: f.size(23, 1400) f.size(150, 1400) f.size(35, 1400);
    &__ttl {
      font-size: f.size(24, 1400);
    }
    &__wrap {
      margin-top: f.size(23, 1400);
      display: flex;
      gap: f.size(45, 1400);
    }
    .qa__content {
      width: calc(100% / 3);
      &--q {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-items: center;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  /* ---------------------------- anxiety -- */

  /* -- works ---------------------------- */
  .works {
    padding: f.size(27, 1400) 0 f.size(38, 1400);
    &__ttl {
      font-size: f.size(46, 1400);
    }
    &__catch {
      font-size: f.size(24, 1400);
      line-height: 0.8;
      span {
        font-size: f.size(40, 1400);
      }
    }
    &__style {
      margin: f.size(33, 1400) f.size(20, 1400) 0;
      justify-content: center;
      gap: f.size(27, 1400);
      &--wrap {
        width: f.size(251, 1400);
        height: f.size(103, 1400);
        border-radius: f.size(10, 1400);
        &.office {
          img {
            width: f.size(27, 1400);
          }
        }
        &.home {
          img {
            width: f.size(41, 1400);
          }
        }
        p {
          margin-top: f.size(11, 1400);
          font-size: f.size(16, 1400);
          @include m.lineHeight(20, 16);
        }
      }
      &--rate {
        font-size: f.size(70, 1400);
        span {
          font-size: f.size(28, 1400);
        }
      }
    }
    &__img {
      background: url('../img/works.jpg') no-repeat;
      background-size: cover;
      background-position: center 35%;
      height: f.size(276, 1400);
      &--bubble {
        width: f.size(173, 1400);
        &.office {
          bottom: f.size(21, 1400);
          left: f.size(294, 1400);
        }
        &.home {
          top: f.size(24, 1400);
          right: f.size(315, 1400);
        }
      }
    }
    &__kinds {
      margin-top: f.size(24, 1400);
      &--ttl {
        font-size: f.size(24, 1400);
      }
      &--wrap {
        margin-top: f.size(48, 1400);
        padding: f.size(76, 1400) f.size(18, 1400) f.size(20, 1400)
          f.size(38, 1400);
        &:nth-of-type(odd) {
          margin-right: f.size(150, 1400);
          border-radius: 0 f.size(20, 1400) f.size(20, 1400) 0;
          .works__kinds--name {
            left: calc(50% - f.size(132, 1400));
          }
          .works__kinds--txt {
            padding-left: f.size(20, 1400);
          }
          .works__kinds--detail {
            padding-left: f.size(150, 1400);
            margin-left: f.size(150, 1400);
          }
        }
        &:nth-of-type(even) {
          margin-left: f.size(150, 1400);
          border-radius: f.size(20, 1400) 0 0 f.size(20, 1400);
          .works__kinds--inner {
            flex-direction: row-reverse;
          }
          .works__kinds--name {
            right: calc(50% - f.size(140, 1400));
          }
          .works__kinds--txt {
            padding-right: f.size(20, 1400);
          }
          .works__kinds--detail {
            padding-right: f.size(150, 1400);
            margin-left: f.size(112, 1400);
          }
        }
        &:nth-of-type(n + 2) {
          margin-top: f.size(74, 1400);
        }
      }
      &--name {
        height: f.size(75, 1400);
        width: f.size(423, 1400);
        font-size: f.size(32, 1400);
        top: calc(-1 * f.size(37, 1400));
      }
      &--inner {
        display: flex;
        align-items: center;
        img {
          height: f.size(161, 1400);
          width: f.size(658, 1400);
        }
      }
      &--txt {
        margin-top: 0;
        font-size: f.size(18, 1400);
        @include m.lineHeight(28, 18);
      }
      &--detail {
        margin: f.size(22, 1400) 0 0;
        width: f.size(930, 1400);
        display: grid;
        justify-content: center;
        &.normal {
          grid-template-columns: repeat(2, 1fr);
          gap: f.size(26, 1400);
          .works__kinds--detail--sum {
            text-align: center;
          }
        }
        &.special {
          gap: f.size(11, 1400) f.size(25, 1400);
          .works__kinds--detail--pay,
          .works__kinds--detail--sum {
            font-size: f.size(16, 1400);
          }
        }
        &--pay {
          height: f.size(38, 1400);
          border-radius: f.size(20, 1400);
          font-size: f.size(16, 1400);
          span {
            font-size: f.size(24, 1400);
          }
          &.office {
            background-color: #ffc0be;
          }
          &.home {
            background-color: f.primary();
          }
        }
        &--sum {
          font-size: f.size(16, 1400);
          span {
            font-size: f.size(28, 1400);
          }
        }
      }
      &--note {
        margin-top: f.size(11, 1400);
        margin-left: f.size(318, 1400);
        font-size: f.size(12, 1400);
      }
    }
  }
  /* ---------------------------- works -- */

  /* -- interview ---------------------------- */
  .interview {
    margin-top: f.size(38, 1400);
    &__ttl {
      font-size: f.size(46, 1400);
      @include m.lineHeight(68, 46);
      & + p {
        font-size: f.size(24, 1400);
        @include m.lineHeight(46, 30);
      }
    }
    &__catch {
      font-size: f.size(40, 1400);
      @include m.lineHeight(40, 46);
    }
    &__content {
      margin: f.size(70, 1400) f.size(150, 1400) f.size(60, 1400);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: f.size(55, 1400);
      &--model {
        margin: 0 f.size(30, 1400);
        &--info {
          font-size: f.size(16, 1400);
          @include m.lineHeight(22, 16);
        }
        &--detail {
          margin-top: f.size(20, 1400);
          font-size: f.size(24, 1400);
          @include m.lineHeight(32, 24);
        }
        &--img {
          width: f.size(242, 1400);
          height: f.size(242, 1400);
        }
      }
      &--txt {
        margin-top: f.size(46, 1400);
        padding: f.size(51, 1400) f.size(27, 1400) f.size(10, 1400);
        font-size: f.size(18, 1400);
        @include m.lineHeight(32, 18);
        &::before {
          top: calc(-1 * f.size(29, 1400));
          left: unset;
          transform: unset;
          border-right: f.size(24, 1400) solid transparent;
          border-left: f.size(24, 1400) solid transparent;
          border-bottom: f.size(42, 1400) solid #f5f5f5;
        }
        span {
          font-size: inherit;
          font-weight: bold;
          color: f.primary();
        }
      }
    }
    &__wrap {
      display: grid;
      grid-template-rows: auto 1fr;
      &:nth-of-type(1) {
        .interview__content--txt {
          &::before {
            right: f.size(125, 1400);
          }
        }
      }
      &:nth-of-type(2) {
        margin-top: 0;
        .interview__content--txt {
          &::before {
            left: f.size(125, 1400);
          }
        }
      }
    }
  }
  /* ---------------------------- interview -- */
  /* -- qa ---------------------------- */
  .qa {
    margin: f.size(38, 1400) f.size(150, 1400) f.size(76, 1400);
    &__ttl {
      text-align: center;
      h2 {
        font-size: f.size(46, 1400);
      }
      p {
        font-size: f.size(24, 1400);
      }
    }
    &__wrap {
      margin-top: f.size(38, 1400);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: f.size(45, 1400);
    }
    &__content {
      &:nth-child(n + 2) {
        margin-top: 0;
      }
      &--q {
        gap: f.size(4, 1400);
        img {
          height: f.size(91, 1400);
          width: f.size(91, 1400);
        }
        p {
          font-size: f.size(18, 1400);
          @include m.lineHeight(27, 18);
        }
      }
      &--border {
        margin-top: 0;
        height: f.size(2, 1400);
        &::after {
          border-right: f.size(7, 1400) solid transparent;
          border-left: f.size(7, 1400) solid transparent;
          border-top: f.size(13, 1400) solid #434343;
          bottom: calc(-1 * f.size(12, 1400));
        }
      }
      &--a {
        margin-top: f.size(22, 1400);
        font-size: f.size(16, 1400);
        span {
          font-size: f.size(20, 1400);
        }
      }
    }
    .form__btn {
      margin-top: f.size(27);
    }
    .qa__content--border {
      margin-top: f.size(16, 1400);
    }
    .qa__wrap {
      gap: f.size(40, 1400) f.size(45, 1400);
    }
    .qa__content {
      padding-bottom: 0;
      display: grid;
      grid-template-rows: f.size(100, 1440) auto 1fr;
      &--q {
        gap: f.size(4, 1400);
        img {
          width: f.size(91, 1400);
          height: f.size(91, 1400);
        }
        p {
          font-size: f.size(18, 1400);
        }
      }
      &:nth-of-type(n + 2) {
        padding-top: 0;
        border-top: none;
      }
    }
  }
  /* ---------------------------- qa -- */
  /* -- footer ---------------------------- */
  .footer {
    padding: f.size(17, 1400) f.size(20, 1400) f.size(25, 1400);
    &__logo {
      width: f.size(246, 1400);
    }
    &__sns {
      margin-top: f.size(16, 1400);
      gap: f.size(12, 1400);
      a {
        width: f.size(34, 1400);
        height: f.size(34, 1400);
      }
    }
    p {
      margin-top: f.size(12, 1400);
      font-size: f.size(10, 1400);
      text-align: center;
    }
    &__link {
      margin-top: 0;
      justify-content: center;
      gap: f.size(19, 1400);
      a {
        font-size: f.size(10, 1400);
      }
    }
  }
  /* ---------------------------- footer -- */
  /* -- office ---------------------------- */
  .offices {
    padding: 0 f.vw(150, 1400);
    margin-top: f.size(25, 1400);
    &__ttl {
      font-size: f.size(46, 1400);
      & + p {
        font-size: f.size(24, 1400);
      }
    }
    &__city {
      margin-top: f.size(15, 1400);
      padding: f.vw(15, 1400) f.vw(17, 1400);
      font-size: f.vw(16, 1400);
    }
    &__list {
      padding: 0 0 f.vw(20, 1400) 0;
      margin-top: f.vw(3, 1400);
    }
    h4 {
      font-size: f.vw(15, 1400);
      padding-left: f.vw(17, 1400);
      padding: f.vw(13, 1400) 0;
      &::after {
        right: f.size(20, 1400);
        border-top: f.vw(6, 1400) solid transparent;
        border-bottom: f.vw(6, 1400) solid transparent;
        border-left: f.vw(11, 1400) solid #d0d0d0;
      }
      &.open {
        &::after {
          transform: rotate(90deg) translateX(calc(-1 * f.vw(5, 1400)));
        }
      }
    }
    &__detail--inner {
      display: grid;
      margin-top: f.vw(25, 1400);
      grid-template-columns: f.vw(340, 1400) 1fr;
      gap: f.vw(45, 1400);
      padding-bottom: f.vw(13, 1400);
    }
    &__img {
      margin-top: 0;
      img {
        width: f.vw(270, 1400);
        height: f.vw(185, 1400);
        border-radius: f.vw(10, 1400);
      }
    }
    &__txt {
      margin-top: 0;
      h5 {
        margin-top: f.vw(20, 1400);
        padding: f.vw(6, 1400) 0;
        font-size: f.vw(14, 1400);
        border-radius: f.vw(40, 1400);
      }
    }
    &__info {
      display: flex;
      gap: f.vw(30, 1400);
      dl {
        gap: f.vw(8, 1400);
      }
      li {
        & + li {
          margin-top: 0;
        }
      }
    }
    &__tag {
      min-width: f.vw(83, 1400);
      font-size: f.vw(14, 1400);
      padding: f.vw(6, 1400) 0;
      border-radius: f.vw(40, 1400);
    }
    &__appeal {
      margin-top: f.vw(11, 1400);
      font-size: f.vw(14, 1400);
    }
  }

  // pager
  .swiper-pagination-bullets {
    position: static;
    margin-top: f.vw(10, 1400);
  }
  .swiper-pagination-bullet {
    width: f.vw(12, 1400) !important;
    height: f.vw(12, 1400) !important;
    margin-left: f.vw(12, 1400) !important;
  }

  // next,prevボタン
  .swiper-button-prev,
  .swiper-button-next {
    top: f.vw(90, 1400);
  }
  .swiper-button-prev::after {
    border-bottom: f.vw(8, 1400) solid transparent;
    border-right: f.vw(15, 1400) solid #ff82a9;
    border-top: f.vw(8, 1400) solid transparent;
  }
  .swiper-button-next::after {
    border-bottom: f.vw(8, 1400) solid transparent;
    border-left: f.vw(15, 1400) solid #ff82a9;
    border-top: f.vw(8, 1400) solid transparent;
  }
  /* ---------------------------- office -- */
}
