@use './function' as f;
@use './mixin' as m;

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  color: inherit;
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-size: 1.6rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: bottom;
}
input,
button,
textarea,
select {
  resize: none;
  font: inherit;
  outline: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  color: inherit;
}
li {
  list-style-type: none;
  color: inherit;
}
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}
button {
  display: inline-block;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition: opacity 0.3s;
}
