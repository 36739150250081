@use './function' as f;
@use './mixin' as m;
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&family=Noto+Sans+JP:wght@400;700&display=swap');

body * {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  color: #434343;
  font-size: f.vw(15);
}

select,
input {
  appearance: none;
  background: transparent;
}

.is-pc {
  display: none;
}

@include m.media(for-pc) {
  .is-pc {
    display: block;
  }
  .is-sp {
    display: none;
  }
}
