@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&family=Noto+Sans+JP:wght@400;700&display=swap");
body * {
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: #434343;
  font-size: clamp(10.5px, 4vw, 18px);
}

select,
input {
  appearance: none;
  background: transparent;
}

.is-pc {
  display: none;
}

@media only screen and (min-width: 768px) {
  .is-pc {
    display: block;
  }
  .is-sp {
    display: none;
  }
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  color: inherit;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: bottom;
}

input,
button,
textarea,
select {
  resize: none;
  font: inherit;
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  color: inherit;
}

li {
  list-style-type: none;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}

button {
  display: inline-block;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition: opacity 0.3s;
}

.header {
  height: 13.3333333333vw;
  padding: 2.6666666667vw 5.3333333333vw;
}
.header img {
  width: 22.6666666667vw;
}

.contact-page {
  margin-bottom: 8vw;
}
.contact-page__ttl {
  text-align: center;
}
.contact-page__ttl h1 {
  font-size: 12vw;
  color: #ff82a9;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.5555555556;
}
.contact-page__ttl p {
  font-size: 4.8vw;
  font-weight: bold;
  line-height: 1;
}
.contact-page__message {
  margin-top: 2.4vw;
  padding: 5.3333333333vw;
  background-color: #ffebe7;
}
.contact-page__message p {
  font-size: 3.7333333333vw;
  color: #434343;
  line-height: 1.8571428571;
}
.contact-page__message.thanks {
  margin-bottom: 10.6666666667vw;
  padding: 6.9333333333vw 4.8vw;
  text-align: center;
  border-radius: 2.6666666667vw;
}
.contact-page.thanks {
  margin: 0 5.3333333333vw 8vw;
}
.contact-page.thanks .contact__btn.back {
  background-color: #fff;
  border: 1px solid #707070;
}
.contact-page .page__ttl__ja {
  color: #606060;
}
.contact-page__form {
  margin: 6.9333333333vw 5.3333333333vw 0;
}
.contact-page__form input,
.contact-page__form select {
  padding: 1.0666666667vw 2.1333333333vw;
  width: 100%;
  height: 13.3333333333vw;
  border: 1px solid #707070;
  outline: none;
}
.contact-page__form input::placeholder,
.contact-page__form select::placeholder {
  color: #bfbfbf;
}
.contact-page__form input.contact__shop,
.contact-page__form select.contact__shop {
  width: 73.0666666667vw;
  height: 10.9333333333vw;
  margin-left: 1.3333333333vw;
}
.contact-page__form input {
  padding: 3.7333333333vw 4.2666666667vw;
}
.contact-page__form input[type=checkbox] {
  display: none;
}
.contact-page__form input[type=checkbox]:checked + span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 5.8666666667vw;
  height: 5.8666666667vw;
  background: url(../img/checked.png) no-repeat;
  background-size: 70%;
  background-position: center;
}
.contact-page__form input[type=radio] {
  display: none;
}
.contact-page__form input[type=radio]:checked + span::after {
  opacity: 1;
}
.contact-page__form textarea {
  padding: 2.9333333333vw 4.2666666667vw;
  width: 100%;
  border: 1px solid #707070;
}
.contact-page__form dt {
  margin-top: 5.8666666667vw;
  font-size: 4.2666666667vw;
  font-weight: bold;
  color: #606060;
}
.contact-page__form dd {
  margin-top: 2.1333333333vw;
}
.contact-page__form dd.mt-m {
  margin-top: 4.2666666667vw;
}
.contact-page__form.confirm .contact__select::before {
  content: none;
}
.contact-page__form.confirm .contact__select::after {
  content: none;
}
.contact-page .contact__select {
  display: inline-block;
  position: relative;
}
.contact-page .contact__select.--wide {
  width: 100%;
}
.contact-page .contact__select::before {
  content: "";
  height: calc(100% - 2px);
  width: 7.4666666667vw;
  background-color: #ffebe7;
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: -1;
}
.contact-page .contact__select::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 1.6vw solid transparent;
  border-left: 1.6vw solid transparent;
  border-top: 2.4vw solid #a7a7a7;
  border-bottom: 0;
  position: absolute;
  top: 50%;
  right: 2.1333333333vw;
  transform: translateY(-50%);
  z-index: -1;
}
.contact-page .contact__birth {
  display: flex;
  gap: 5.3333333333vw;
}
.contact-page .contact__birth select {
  width: 29.3333333333vw;
  height: 9.3333333333vw;
}
.contact-page .contact__birth .contact__select:nth-of-type(n + 2) select {
  width: 24.8vw;
}
.contact-page .contact__contact + span {
  margin-right: 4vw;
}
.contact-page .contact__checkbox {
  height: 5.8666666667vw;
  width: 5.8666666667vw;
  position: relative;
}
.contact-page .contact__checkbox + span {
  position: relative;
  padding-left: 6.9333333333vw;
}
.contact-page .contact__checkbox + span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5.8666666667vw;
  height: 5.8666666667vw;
  display: inline-block;
  border: 1px solid #404040;
}
.contact-page .contact__works {
  height: 9.3333333333vw;
  width: 44.8vw;
}
.contact-page .contact__area {
  margin-left: 1.3333333333vw;
  height: 9.3333333333vw;
  width: 30.1333333333vw;
}
.contact-page .contact__shopname {
  margin-left: 1.3333333333vw;
  height: 9.3333333333vw;
  width: 74.6666666667vw;
}
.contact-page .contact__experience {
  height: 6.1333333333vw;
  width: 6.1333333333vw;
  position: relative;
}
.contact-page .contact__experience + span {
  margin-right: 9.6vw;
  position: relative;
  padding-left: 6.9333333333vw;
}
.contact-page .contact__experience + span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 6.1333333333vw;
  width: 6.1333333333vw;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #707070;
}
.contact-page .contact__experience + span::after {
  content: "";
  position: absolute;
  top: 1.0666666667vw;
  left: 1.0666666667vw;
  display: block;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  background-color: #707070;
  opacity: 0;
}
.contact-page .contact__interview select {
  height: 9.3333333333vw;
  width: 24.8vw;
}
.contact-page .contact__interview .contact__select:last-of-type select {
  margin-left: 5.3333333333vw;
}
.contact-page .contact__center {
  text-align: center;
}
.contact-page .contact__privacy {
  font-size: 3.2vw;
  color: #606060;
  margin-top: 2.6666666667vw;
  padding: 1.3333333333vw 4.2666666667vw 1.3333333333vw 1.8666666667vw;
  height: 39.2vw;
  overflow-y: auto;
  border: 1px solid #707070;
}
.contact-page .contact__privacy-check {
  margin-top: 4.5333333333vw;
  text-align: center;
}
.contact-page .contact__privacy-check input[name="privacy[data][]"] {
  height: 5.8666666667vw;
  width: 5.8666666667vw;
}
.contact-page .contact__privacy-check a {
  text-decoration: underline;
  margin-left: 2.4vw;
  font-size: 4vw;
}
.contact-page .contact__privacy p {
  font-size: 3.2vw;
}
.contact-page .contact__btn {
  margin-top: 7.2vw;
  height: 14.9333333333vw;
  width: 100%;
  border-radius: 13.3333333333vw;
  font-size: 5.3333333333vw;
  font-weight: 500;
  text-align: center;
  color: #fff;
  transition: opacity 0.3s;
}
.contact-page .contact__btn.confirm {
  background-image: linear-gradient(135deg, rgb(255, 130, 169), rgb(255, 192, 190));
}
.contact-page .contact__btn.submit {
  background-image: linear-gradient(135deg, rgb(255, 130, 169), rgb(255, 192, 190));
}
.contact-page .contact__btn.back {
  margin-top: 4vw;
  color: #404040;
  background-color: #f0f0f0;
}
.contact-page .contact__btn.back a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 5.3333333333vw;
}
.contact-page .contact__btn:hover {
  opacity: 0.5;
}
.contact-page .contact__note {
  color: #606060;
  font-size: 3.2vw;
}
.contact-page .contact__note-red {
  color: #ff0000;
  font-size: 3.2vw;
  font-weight: bold;
}
.contact-page .contact__required {
  display: inline-block;
  padding-right: 12vw;
  position: relative;
}
.contact-page .contact__required::after {
  position: absolute;
  right: 0;
  content: "必須";
  height: 5.8666666667vw;
  width: 10.6666666667vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2vw;
  color: #fff;
  background-color: #be0000;
  border-radius: 21.3333333333vw;
  top: 50%;
  transform: translateY(-50%);
}
.contact-page.confirm .contact__select::before {
  content: none;
}
.contact-page.confirm .contact__select::after {
  content: none;
}
.contact-page.confirm .contact__privacy-check input[type=checkbox] {
  display: none;
}
.contact-page .error {
  display: block;
  font-size: 3.7333333333vw;
  color: #be0000;
}

.mw_wp_form .error {
  position: absolute;
}

/* -- responsive ---------------------------- */
/* ---------------------------- responsive -- */
@media only screen and (min-width: 768px) {
  .header {
    height: 5.7142857143vw;
    padding: 1.0714285714vw 10.7142857143vw;
  }
  .header img {
    width: 9.2857142857vw;
  }
  .contact-page {
    margin: 2.5714285714vw 10.7142857143vw 2.8571428571vw;
  }
  .contact-page__ttl h1 {
    font-size: 3.2142857143vw;
  }
  .contact-page__ttl p {
    font-size: 1.2857142857vw;
  }
  .contact-page__message {
    margin-top: 2.0714285714vw;
    padding: 2.4285714286vw 5.1428571429vw 2.8571428571vw;
    text-align: center;
  }
  .contact-page__message p {
    font-size: 1vw;
  }
  .contact-page__message.confirm {
    text-align: left;
  }
  .contact-page__message.thanks {
    margin-bottom: 2.3571428571vw;
    padding: 2vw 1.4285714286vw 3.2857142857vw;
    text-align: center;
    border-radius: 0;
  }
  .contact-page__message.thanks p {
    font-size: 1.1428571429vw;
  }
  .contact-page.thanks {
    margin: 0 10.7142857143vw 2.1428571429vw;
  }
  .contact-page.thanks .contact__btn.back {
    width: 31.0714285714vw;
  }
  .contact-page .page__ttl__ja {
    color: #606060;
  }
  .contact-page__form {
    margin: 3.2857142857vw 7.8571428571vw 0;
  }
  .contact-page__form input,
  .contact-page__form select {
    padding: 0.2857142857vw 0.5714285714vw;
    height: 4.1428571429vw;
  }
  .contact-page__form input.contact__shop,
  .contact-page__form select.contact__shop {
    width: 23.0714285714vw;
    height: 2.8472222222vw;
    margin-left: 0.3571428571vw;
  }
  .contact-page__form input {
    padding: 1vw 1.1428571429vw;
  }
  .contact-page__form input[type=checkbox]:checked + span::before {
    width: 1.7857142857vw;
    height: 1.7857142857vw;
  }
  .contact-page__form textarea {
    height: 38.2857142857vw;
    padding: 0.7857142857vw 1.1428571429vw;
  }
  .contact-page__form dt {
    margin-top: 1.5714285714vw;
    font-size: 1.2857142857vw;
  }
  .contact-page__form dd {
    margin-top: 0.7142857143vw;
  }
  .contact-page__form dd.mt-m {
    margin-top: 1.1428571429vw;
  }
  .contact-page .contact__select::before {
    height: calc(100% - 2px);
    width: 2.3571428571vw;
  }
  .contact-page .contact__select::after {
    border-right: 0.4285714286vw solid transparent;
    border-left: 0.4285714286vw solid transparent;
    border-top: 0.6428571429vw solid #a7a7a7;
    right: 0.7857142857vw;
  }
  .contact-page .contact__birth {
    gap: 1.2142857143vw;
  }
  .contact-page .contact__birth select {
    width: 9.0714285714vw;
    height: 2.8571428571vw;
  }
  .contact-page .contact__birth .contact__select:nth-of-type(n + 2) select {
    width: 7.6428571429vw;
  }
  .contact-page .contact__contact + span {
    margin-right: 1.4285714286vw;
  }
  .contact-page .contact__checkbox {
    height: 1.7857142857vw;
    width: 1.7857142857vw;
  }
  .contact-page .contact__checkbox + span {
    padding-left: 2.1428571429vw;
  }
  .contact-page .contact__checkbox + span::before {
    width: 1.7857142857vw;
    height: 1.7857142857vw;
  }
  .contact-page .contact__works {
    height: 2.9285714286vw;
    width: 13.7857142857vw;
  }
  .contact-page .contact__area {
    margin-left: 0.3571428571vw;
    height: 2.8571428571vw;
    width: 9.2857142857vw;
  }
  .contact-page .contact__shopname {
    margin-top: 0.4285714286vw;
    margin-left: 0.3571428571vw;
    height: 2.8571428571vw;
    width: 23.0714285714vw;
  }
  .contact-page .contact__experience {
    height: 1.7857142857vw;
    width: 1.7857142857vw;
    position: relative;
  }
  .contact-page .contact__experience + span {
    margin-right: 1.4285714286vw;
    padding-left: 2.1428571429vw;
  }
  .contact-page .contact__experience + span::before {
    height: 1.7857142857vw;
    width: 1.7857142857vw;
    top: 50%;
    transform: translateY(-50%);
  }
  .contact-page .contact__experience + span::after {
    top: 50%;
    transform: translateY(-50%);
    left: 8%;
    width: 1.2142857143vw;
    height: 1.2142857143vw;
  }
  .contact-page .contact__interview select {
    height: 2.5vw;
    width: 6.6428571429vw;
  }
  .contact-page .contact__interview .contact__select {
    width: 23.8571428571vw;
  }
  .contact-page .contact__interview .contact__select:last-of-type select {
    margin-left: 0.7857142857vw;
  }
  .contact-page .contact__privacy {
    font-size: 0.8571428571vw;
    margin-top: 0.9285714286vw;
    padding: 0.3571428571vw 1.3571428571vw 0.3571428571vw 0.5714285714vw;
    height: 12.0714285714vw;
  }
  .contact-page .contact__privacy-check {
    margin-top: 1.4285714286vw;
  }
  .contact-page .contact__privacy-check input[name="privacy[data][]"] {
    height: 1.7857142857vw;
    width: 1.7857142857vw;
  }
  .contact-page .contact__privacy-check a {
    margin-left: 0.7142857143vw;
    font-size: 1.0714285714vw;
  }
  .contact-page .contact__privacy p {
    font-size: 0.8571428571vw;
  }
  .contact-page .contact__btn {
    margin-top: 2.1428571429vw;
    height: 4.6428571429vw;
    width: 31.2142857143vw;
    border-radius: 3.5714285714vw;
    font-size: 1.4285714286vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .contact-page .contact__btn.back {
    margin-top: 1.2857142857vw;
  }
  .contact-page .contact__btn.back a {
    font-size: 1.4285714286vw;
  }
  .contact-page .contact__note {
    font-size: 1vw;
  }
  .contact-page .contact__note-red {
    font-size: 1vw;
  }
  .contact-page .contact__required {
    padding-right: 4.0714285714vw;
  }
  .contact-page .contact__required::after {
    height: 1.7857142857vw;
    width: 3.2857142857vw;
    font-size: 1vw;
    border-radius: 5.7142857143vw;
  }
  .contact-page .error {
    font-size: 1vw;
  }
}
.notpage {
  margin: 17.3333333333vw 5.3333333333vw 7.4666666667vw;
}
.notpage__ttl {
  text-align: center;
}
.notpage__ttl h1 {
  font-size: 9.6vw;
  font-weight: bold;
}
.notpage__message {
  margin-bottom: 8vw;
  margin-top: 5.0666666667vw;
  padding: 7.4666666667vw 4vw 9.8666666667vw 5.0666666667vw;
  background-color: #f8f8f8;
  border-radius: 2.6666666667vw;
}
.notpage__message p {
  font-size: 4vw;
  text-align: center;
}

.privacy {
  margin: 12.8vw 5.3333333333vw 7.4666666667vw;
}
.privacy h1 {
  font-size: 4.8vw;
  font-weight: bold;
  text-align: center;
}
.privacy__txt {
  margin-top: 1.3333333333vw;
  margin-bottom: 9.3333333333vw;
  color: #606060;
  font-size: 4vw;
  line-height: 1.8666666667;
  word-break: break-all;
}

@media only screen and (min-width: 768px) {
  .notpage {
    margin: 3.2857142857vw 10.7142857143vw 20vw;
  }
  .notpage__ttl h1 {
    font-size: 2.5714285714vw;
  }
  .notpage__message {
    margin-bottom: 0;
    margin-top: 7.5714285714vw;
    padding: 2vw 5.0714285714vw 3vw;
    border-radius: 0;
  }
  .notpage__message p {
    font-size: 1.1428571429vw;
  }
  .privacy {
    margin: 3.4285714286vw 18.9285714286vw 2.8571428571vw;
  }
  .privacy h1 {
    font-size: 1.4285714286vw;
  }
  .privacy__txt {
    margin-top: 1.4285714286vw;
    margin-bottom: 2.5vw;
    font-size: 1.0714285714vw;
  }
}
.notpage {
  margin: 17.3333333333vw 5.3333333333vw 7.4666666667vw;
}
.notpage__ttl {
  text-align: center;
}
.notpage__ttl h1 {
  font-size: 9.6vw;
  font-weight: bold;
}
.notpage__message {
  margin-bottom: 8vw;
  margin-top: 5.0666666667vw;
  padding: 7.4666666667vw 4vw 9.8666666667vw 5.0666666667vw;
  background-color: #f8f8f8;
  border-radius: 2.6666666667vw;
}
.notpage__message p {
  font-size: 4vw;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .notpage {
    margin: 3.2857142857vw 10.7142857143vw 20vw;
  }
  .notpage__ttl h1 {
    font-size: 2.5714285714vw;
  }
  .notpage__message {
    margin-bottom: 0;
    margin-top: 7.5714285714vw;
    padding: 2vw 5.0714285714vw 3vw;
    border-radius: 0;
  }
  .notpage__message p {
    font-size: 1.1428571429vw;
  }
}
/* -- topCatch ---------------------------- */
.topCatch {
  padding: 1.8666666667vw 3.4666666667vw;
  background-color: #ff82a9;
  height: 16.2666666667vw;
}
.topCatch__txt {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}
.topCatch__txt::before, .topCatch__txt::after {
  content: "";
  height: 90%;
  width: 0.5333333333vw;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 50%;
}
.topCatch__txt::before {
  transform-origin: top left;
  left: 0;
  transform: translateY(-50%) rotate(-17deg);
}
.topCatch__txt::after {
  transform-origin: top right;
  right: 0;
  transform: translateY(-50%) rotate(17deg);
}
.topCatch__txt--first {
  font-size: 3.7333333333vw;
  line-height: 1;
}
.topCatch__txt--second {
  font-size: 5.3333333333vw;
}
.topCatch__txt--second span {
  color: #fff;
  font-weight: bold;
  font-size: 6.4vw;
  position: relative;
}
.topCatch__txt--second span::after {
  content: "";
  height: 1.0666666667vw;
  width: 1.0666666667vw;
  background-color: #fff8a4;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* ---------------------------- topCatch -- */
/* -- fv ---------------------------- */
.fv {
  position: relative;
}
.fv__img {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.fv h1 {
  font-size: 10.1333333333vw;
  font-weight: bold;
  text-align: center;
  position: relative;
  line-height: 1.4736842105;
}
.fv h1::after {
  content: attr(data-ja);
  font-size: 3.2vw;
  font-weight: bold;
  position: absolute;
  bottom: -1.3333333333vw;
  left: 50%;
  transform: translateX(-50%);
}
.fv__catch--wrap {
  display: flex;
  justify-content: center;
}
.fv__catch--wrap p {
  font-weight: bold;
  text-align: center;
  font-size: 4vw;
}
.fv__catch--wrap p span {
  font-size: 4.8vw;
  font-weight: bold;
  color: #ff82a9;
}
.fv__catch--wrap p + p {
  filter: drop-shadow(-1px 2px 0 white);
}
.fv__feature {
  width: 100vw;
  padding: 1.6vw 0 2.1333333333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.fv__feature::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f2f2f2;
  z-index: -2;
}
.fv__feature div {
  display: flex;
  align-items: center;
}
.fv__feature div p {
  font-size: 4vw;
  font-weight: bold;
}
.fv__feature div img:nth-of-type(1) {
  width: 8vw;
  margin-right: 1.8666666667vw;
}
.fv__feature div img:nth-of-type(2) {
  width: 5.8666666667vw;
  margin-left: 1.6vw;
}
.fv__feature div + p {
  font-size: 4vw;
  font-weight: bold;
  line-height: 1;
  margin-left: 4vw;
}
.fv__content {
  margin-top: 26.6666666667vw;
}
.fv__content--rank {
  width: 47.2vw;
  margin-left: auto;
  margin-right: 4vw;
}
.fv__content--point {
  margin: 1.3333333333vw 3.7333333333vw 0;
  display: flex;
  justify-content: space-between;
}
.fv__content--point--wrap {
  height: 30.6666666667vw;
  width: 33.3333333333%;
  background: url("../img/point_bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-weight: bold;
}
.fv__content--point--wrap p {
  margin-top: 4.8vw;
  text-align: center;
  font-size: 3.2vw;
}
.fv__content--point--wrap p::first-letter {
  text-transform: uppercase;
}
.fv__content--point--wrap p + p {
  margin-top: 1.0666666667vw;
  line-height: 1.3333333333;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
  position: relative;
  font-size: 4vw;
}
.fv__content--point--wrap:nth-last-of-type(2) {
  background: url("../img/point_bg_reverse.png") no-repeat;
  background-size: cover;
}
.fv__content--pay {
  margin-top: -16.5333333333vw;
  background-color: #ffebe7;
  z-index: -1;
  padding: 19.4666666667vw 5.3333333333vw 2.6666666667vw;
  display: flex;
  justify-content: space-between;
  gap: 2.1333333333vw;
}
.fv__content--pay--wrap {
  text-align: center;
}
.fv__content--pay--wrap:nth-of-type(1) {
  width: 27.7333333333vw;
}
.fv__content--pay--wrap:nth-of-type(2) {
  width: 27.7333333333vw;
}
.fv__content--pay--wrap:nth-of-type(3) {
  width: 29.3333333333vw;
}
.fv__content--pay--ttl {
  padding: 1.8666666667vw 0;
  line-height: 1;
  background-color: #fff;
  border-radius: 3.7333333333vw;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.16));
  font-size: 3.2vw;
  font-weight: bold;
}
.fv__content--pay--num {
  margin-top: 2.1333333333vw;
  font-size: 9.0666666667vw;
  color: #ff82a9;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fv__content--pay--num .big {
  color: inherit;
  font-weight: bold;
  font-size: 7.4666666667vw;
  font-family: inherit;
}
.fv__content--pay--num .small {
  color: inherit;
  font-weight: bold;
  font-family: inherit;
  font-size: 3.2vw;
  align-self: flex-end;
  margin-bottom: 1.0666666667vw;
}

/* ---------------------------- fv -- */
/* -- worries ---------------------------- */
.worries__ttl {
  background-color: #f5f5f5;
  filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.16));
  position: relative;
  padding: 2.6666666667vw 0 4vw;
  text-align: center;
  font-size: 4.8vw;
  font-weight: bold;
}
.worries__ttl::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 25px solid #f5f5f5;
  border-bottom: 0;
  position: absolute;
  bottom: -4vw;
  left: 50%;
  transform: translateX(-50%);
}
.worries__content {
  margin-top: 3.7333333333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.8666666667vw;
}
.worries__content img {
  height: 30.1333333333vw;
  width: 30.1333333333vw;
}
.worries__txt {
  display: grid;
  gap: 4vw;
  position: relative;
}
.worries__txt::after {
  content: attr(data-other);
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 4vw;
  font-weight: bold;
}
.worries__txt p {
  font-size: 4vw;
  font-weight: bold;
  position: relative;
  text-decoration-color: #ffff3c;
  text-decoration: underline;
  text-decoration-thickness: 1.3333333333vw;
}
.worries__txt p::after {
  content: "";
  height: 1.3333333333vw;
  width: 100%;
  background-color: #ffff3c;
}

/* ---------------------------- worries -- */
/* -- merit ---------------------------- */
.merit {
  margin-top: 4.8vw;
  padding: 4.5333333333vw 0 3.4666666667vw;
  background-color: #ff82a9;
}
.merit__catch {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6vw;
}
.merit__catch p {
  display: inline-block;
  background-color: #fff;
  color: #ff82a9;
  font-size: 5.0666666667vw;
  font-weight: bold;
}
.merit__catch p span {
  font-size: 6.4vw;
  color: inherit;
  font-weight: inherit;
}
.merit__wrap {
  margin-top: 3.2vw;
}
.merit__wrap img {
  height: 48.8vw;
}
.merit__content {
  margin-top: 4vw;
}
.merit__list {
  display: flex;
  justify-content: center;
  gap: 1.0666666667vw;
}
.merit__list li {
  background-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
  font-size: 3.7333333333vw;
  font-weight: bold;
  text-align: center;
  color: #606060;
}
.merit__list li:nth-of-type(1), .merit__list li:nth-of-type(3) {
  width: 23.4666666667vw;
}
.merit__list li:nth-of-type(2) {
  width: 28.2666666667vw;
}
.merit__list li:nth-of-type(4) {
  font-size: 3.2vw;
  font-weight: normal;
  background-color: unset;
  align-self: flex-end;
  line-height: 1;
  box-shadow: none;
}
.merit__txt {
  margin-top: 2.1333333333vw;
}
.merit__txt p {
  font-size: 6.4vw;
  font-weight: bold;
  text-align: center;
  color: #fff;
}
.merit__txt p span {
  font-size: 5.6vw;
  color: inherit;
}
.merit__txt p + p {
  font-size: 6.9333333333vw;
}

/* ---------------------------- merit -- */
/* -- form ---------------------------- */
.form {
  padding: 4.8vw 5.3333333333vw 4vw;
  background-image: linear-gradient(180deg, rgb(255, 192, 190), rgb(255, 235, 231));
}
.form p {
  margin-top: 2.6666666667vw;
  text-align: center;
  font-weight: 500;
  font-size: 4vw;
}
.form__btn {
  margin-top: 2.6666666667vw;
}
.form__btn button {
  width: 100%;
  height: 17.0666666667vw;
  border: 3px solid #434343;
  border-radius: 2.6666666667vw;
  background-color: #fff;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
}
.form__btn button a {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2.1333333333vw;
  font-size: 5.8666666667vw;
  font-weight: bold;
  position: relative;
  padding: 0 3.4666666667vw 0 4.5333333333vw;
}
.form__btn button a::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 11px solid #434343;
  border-right: 0;
  position: absolute;
  top: 53%;
  right: 3.4666666667vw;
  transform: translateY(-50%);
}
.form__btn button a img {
  width: 8.2666666667vw;
  height: auto;
}
.form__btn button a:hover {
  opacity: 0.7;
}
.form__btn.fixed_sp {
  position: fixed;
  bottom: 5.3333333333vw;
  width: 89.3333333333vw;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: 0.3s;
  z-index: 500;
}
.form__btn.fixed_sp.hidden {
  opacity: 0;
  visibility: hidden;
}
.form__link {
  margin-top: 4vw;
  font-size: 3.7333333333vw;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

/* ---------------------------- form -- */
/* -- point ---------------------------- */
.point {
  margin-top: 2.9333333333vw;
  margin-bottom: 3.4666666667vw;
}
.point__ttl {
  font-size: 9.6vw;
  color: #ff82a9;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.point__catch {
  font-size: 4.8vw;
  text-align: center;
  font-weight: bold;
}
.point__catch span {
  font-size: 8.5333333333vw;
  color: #ff82a9;
}
.point__wrap {
  margin-top: 14.4vw;
  position: relative;
}
.point__wrap:nth-of-type(n + 2) {
  margin-top: 16.8vw;
}
.point__wrap--imgTtl {
  position: absolute;
  top: -8.2666666667vw;
  left: 5.3333333333vw;
  width: 36vw;
}
.point__wrap--ttl {
  padding: 2.6666666667vw 0 2.1333333333vw;
  background-color: #ff82a9;
  font-size: 6.4vw;
  color: #fff;
  text-align: center;
  font-weight: bold;
}
.point__wrap--ttl span {
  font-size: 5.3333333333vw;
  color: inherit;
}
.point__wrap.bg_gray {
  background-color: #f5f5f5;
}
.point__inner + img {
  height: 37.8666666667vw;
}
.point__txt {
  margin: 0 5.3333333333vw;
}
.point__txt p {
  color: #606060;
  font-size: 3.7333333333vw;
  margin-top: 5.3333333333vw;
  line-height: 2;
}
.point__txt p span {
  color: #ff82a9;
  font-weight: bold;
  font-size: inherit;
}
.point__pay {
  margin: 5.3333333333vw 5.3333333333vw 0;
}
.point__pay--note {
  color: #606060;
  font-size: 3.7333333333vw;
  line-height: 2;
}
.point__pay--wrap {
  margin-top: 5.3333333333vw;
}
.point__pay--wrap:nth-of-type(n + 2) {
  margin-top: 2.1333333333vw;
}
.point__pay--ttl {
  height: 8.5333333333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.7333333333vw;
  color: #fff;
  font-weight: bold;
}
.point__pay--ttl.office {
  background-color: #ffc0be;
}
.point__pay--ttl.house {
  background-color: #ff82a9;
}
.point__pay--detail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4.8vw;
}
.point__pay--txt {
  margin-top: 2.1333333333vw;
}
.point__pay--txt p {
  text-align: center;
  color: #606060;
}
.point__pay--txt p:first-of-type {
  font-size: 3.7333333333vw;
  padding-bottom: 2.6666666667vw;
  border-bottom: 1px dashed #606060;
}
.point__pay--txt p:last-of-type {
  font-weight: bold;
  font-size: 12.2666666667vw;
}
.point__pay--txt p:last-of-type span {
  color: inherit;
  font-weight: inherit;
  font-size: 5.3333333333vw;
}
.point__demand {
  margin: 4.8vw 5.3333333333vw 0;
}
.point__demand--ttl {
  text-align: center;
  position: relative;
  padding: 2.1333333333vw 0;
  font-size: 3.7333333333vw;
  font-weight: normal;
  background-color: #fff;
  color: #606060;
}
.point__demand--ttl::before, .point__demand--ttl::after {
  content: "";
  width: 100%;
  height: 1.0666666667vw;
  border-top: 1px solid #606060;
  border-bottom: 1px solid #606060;
  position: absolute;
  background-color: #f5f5f5;
  left: 50%;
  transform: translateX(-50%);
}
.point__demand--ttl::before {
  top: 0;
}
.point__demand--ttl::after {
  bottom: 0;
}
.point__demand--list {
  margin-top: 2.6666666667vw;
}
.point__demand--list li {
  color: #606060;
  line-height: 2;
  padding-left: 5.3333333333vw;
  position: relative;
  font-size: 3.7333333333vw;
}
.point__demand--list li::before {
  content: "";
  height: 2.1333333333vw;
  width: 2.1333333333vw;
  border-radius: 50%;
  background-color: #434343;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.point__ease {
  margin: 3.4666666667vw 5.3333333333vw 0;
}
.point__ease--ttl {
  font-size: 4.8vw;
  font-weight: bold;
  color: #fff;
  height: 12.2666666667vw;
  background-color: #ff82a9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13.3333333333vw;
  position: relative;
}
.point__ease--ttl::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 25px solid #ff82a9;
  border-bottom: 0;
  position: absolute;
  bottom: -3.2vw;
  left: 50%;
  transform: translateX(-50%);
}
.point__ease--content {
  margin-top: 4.5333333333vw;
  padding-bottom: 4.2666666667vw;
}
.point__ease--content--wrap {
  margin-bottom: 3.7333333333vw;
}
.point__ease--content--wrap:nth-of-type(n + 2) {
  padding-top: 3.4666666667vw;
  border-top: 1px dashed #707070;
}
.point__ease--content--ttl {
  display: flex;
  align-items: center;
  gap: 2.1333333333vw;
  font-size: 4.8vw;
  font-weight: bold;
}
.point__ease--content--ttl img {
  height: 18.6666666667vw;
  width: 18.6666666667vw;
}
.point__ease--content--txt {
  font-size: 3.7333333333vw;
  line-height: 2;
}
.point__ease--content--txt span {
  color: #ff82a9;
  font-weight: bold;
  font-size: inherit;
}

/* ---------------------------- point -- */
/* -- anxiety ---------------------------- */
.anxiety {
  margin: 5.3333333333vw 5.3333333333vw 4.8vw;
}
.anxiety__ttl {
  font-size: 5.3333333333vw;
  font-weight: bold;
  text-align: center;
}
.anxiety__wrap {
  margin-top: 5.3333333333vw;
}

/* ---------------------------- anxiety -- */
/* -- works ---------------------------- */
.works {
  padding: 2.9333333333vw 0 8.8vw;
  background-color: #f5f5f5;
}
.works__ttl {
  font-size: 9.6vw;
  font-weight: bold;
  text-transform: uppercase;
  color: #cbcbcb;
  text-align: center;
}
.works__catch {
  font-size: 4.8vw;
  font-weight: bold;
  text-align: center;
}
.works__catch span {
  font-size: 8.5333333333vw;
  font-weight: bold;
  color: #ff82a9;
}
.works__style {
  margin: 2.4vw 5.3333333333vw 0;
  display: flex;
  justify-content: space-between;
}
.works__style--wrap {
  width: 41.8666666667vw;
  height: 21.6vw;
  border-radius: 2.6666666667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.works__style--wrap.office {
  background-color: #ffc0be;
}
.works__style--wrap.office img {
  width: 5.6vw;
}
.works__style--wrap.home {
  background-color: #ff82a9;
}
.works__style--wrap.home img {
  width: 8.2666666667vw;
}
.works__style--wrap p {
  font-size: 3.7333333333vw;
  font-weight: bold;
  color: #fff;
  line-height: 1.4285714286;
}
.works__style--rate {
  font-size: 12.2666666667vw;
  font-weight: bold;
  text-align: center;
}
.works__style--rate.office {
  color: #ffc0be;
}
.works__style--rate.home {
  color: #ff82a9;
}
.works__style--rate span {
  font-size: 4.8vw;
  font-weight: bold;
  color: inherit;
}
.works__img {
  background: url("../img/works.jpg") no-repeat;
  background-size: cover;
  height: 37.8666666667vw;
  position: relative;
}
.works__img--bubble {
  width: 26.1333333333vw;
  position: absolute;
}
.works__img--bubble.office {
  bottom: 3.4666666667vw;
  left: 5.3333333333vw;
}
.works__img--bubble.home {
  top: 1.8666666667vw;
  right: 6.6666666667vw;
}
.works__kinds {
  margin-top: 4.5333333333vw;
}
.works__kinds--ttl {
  font-size: 4.8vw;
  font-weight: bold;
  text-align: center;
  color: #606060;
}
.works__kinds--wrap {
  margin-top: 16.2666666667vw;
  padding: 3.7333333333vw;
  background-color: #fff;
  position: relative;
}
.works__kinds--wrap:nth-of-type(odd) {
  margin-right: 5.3333333333vw;
  padding-left: 0;
  border-radius: 0 1.3333333333vw 1.3333333333vw 0;
}
.works__kinds--wrap:nth-of-type(odd) .works__kinds--name {
  left: 5.3333333333vw;
}
.works__kinds--wrap:nth-of-type(odd) .works__kinds--txt {
  padding-left: 5.3333333333vw;
}
.works__kinds--wrap:nth-of-type(odd) .works__kinds--detail {
  padding-left: 5.3333333333vw;
}
.works__kinds--wrap:nth-of-type(even) {
  margin-left: 5.3333333333vw;
  padding-right: 0;
  border-radius: 1.3333333333vw 0 0 1.3333333333vw;
}
.works__kinds--wrap:nth-of-type(even) .works__kinds--name {
  right: 5.3333333333vw;
}
.works__kinds--wrap:nth-of-type(even) .works__kinds--txt {
  padding-right: 5.3333333333vw;
}
.works__kinds--wrap:nth-of-type(even) .works__kinds--detail {
  padding-right: 5.3333333333vw;
}
.works__kinds--wrap:nth-of-type(n + 2) {
  margin-top: 17.3333333333vw;
}
.works__kinds--name {
  background: url("../img/ribon2.png") no-repeat;
  background-size: cover;
  height: 16vw;
  width: 89.3333333333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6.4vw;
  font-weight: bold;
  color: #ff82a9;
  position: absolute;
  top: -13.3333333333vw;
}
.works__kinds--inner img {
  height: 32.2666666667vw;
  width: 90.9333333333vw;
}
.works__kinds--txt {
  margin-top: 2.6666666667vw;
  font-size: 3.7333333333vw;
  line-height: 1.5714285714;
  color: #606060;
}
.works__kinds--txt span {
  font-size: inherit;
  color: #ff82a9;
  font-weight: bold;
}
.works__kinds--detail {
  margin: 3.7333333333vw 3.7333333333vw 0;
  display: grid;
}
.works__kinds--detail.normal {
  gap: 2.9333333333vw;
}
.works__kinds--detail.normal .works__kinds--detail--sum {
  text-align: left;
}
.works__kinds--detail.special {
  grid-template-columns: repeat(2, 1fr);
  gap: 2.9333333333vw 2.4vw;
}
.works__kinds--detail.special .works__kinds--detail--pay,
.works__kinds--detail.special .works__kinds--detail--sum {
  font-size: 3.4666666667vw;
}
.works__kinds--detail--pay {
  height: 8.2666666667vw;
  border-radius: 5.3333333333vw;
  font-size: 3.7333333333vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.works__kinds--detail--pay span {
  font-size: 5.3333333333vw;
  color: inherit;
  font-weight: bold;
}
.works__kinds--detail--pay.office {
  background-color: #ffc0be;
}
.works__kinds--detail--pay.home {
  background-color: #ff82a9;
}
.works__kinds--detail--sum {
  font-size: 3.7333333333vw;
  color: #606060;
  text-align: center;
}
.works__kinds--detail--sum span {
  font-size: 5.3333333333vw;
  color: inherit;
  font-weight: bold;
}
.works__kinds--detail--sum.office span {
  color: #ffc0be;
}
.works__kinds--detail--sum.home span {
  color: #ff82a9;
}
.works__kinds--note {
  margin-top: 3.7333333333vw;
  margin-left: 9.0666666667vw;
  font-size: 3.2vw;
  color: #606060;
}

/* ---------------------------- works -- */
/* -- interview ---------------------------- */
.interview {
  margin-top: 3.4666666667vw;
}
.interview__ttl {
  font-size: 9.6vw;
  font-weight: bold;
  color: #ff82a9;
  text-transform: uppercase;
  text-align: center;
}
.interview__ttl + p {
  font-size: 4.8vw;
  font-weight: bold;
  line-height: 1.4444444444;
  text-align: center;
}
.interview__catch {
  font-size: 7.4666666667vw;
  font-weight: bold;
  color: #ff82a9;
  text-align: center;
}
.interview__content {
  margin-top: 12.2666666667vw;
}
.interview__content--model {
  margin: 0 5.3333333333vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.interview__content--model--info {
  color: #606060;
  font-size: 4vw;
  line-height: 1.4666666667;
}
.interview__content--model--detail {
  margin-top: 0.8vw;
  font-size: 5.3333333333vw;
  font-weight: bold;
  color: #ff82a9;
  line-height: 1.4;
}
.interview__content--model--img {
  width: 47.4666666667vw;
  height: 47.4666666667vw;
}
.interview__content--model--img img {
  width: auto;
  height: 100%;
}
.interview__content--txt {
  color: #606060;
  margin-top: 5.6vw;
  padding: 9.8666666667vw 5.0666666667vw 7.2vw 5.3333333333vw;
  font-size: 4vw;
  line-height: 1.4666666667;
  background-color: #f5f5f5;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: -1;
}
.interview__content--txt::before {
  content: "";
  position: absolute;
  top: -5.6vw;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  border-bottom: 42px solid #f5f5f5;
  border-top: 0;
}
.interview__content--txt span {
  font-size: inherit;
  font-weight: bold;
  color: #ff82a9;
}
.interview__wrap:nth-of-type(2) {
  margin-top: 8vw;
}
.interview__wrap:nth-of-type(2) .interview__content--model {
  flex-direction: row-reverse;
}
.interview__wrap:nth-of-type(2) .interview__content--model--info, .interview__wrap:nth-of-type(2) .interview__content--model--detail {
  text-align: right;
}

/* ---------------------------- interview -- */
/* -- qa ---------------------------- */
.qa {
  margin: 1.8666666667vw 5.3333333333vw 7.4666666667vw;
}
.qa__ttl {
  text-align: center;
}
.qa__ttl h2 {
  font-size: 9.6vw;
  color: #ff82a9;
  font-weight: bold;
  text-transform: uppercase;
}
.qa__ttl p {
  font-size: 4.8vw;
  color: #434343;
  font-weight: bold;
}
.qa__wrap {
  margin-top: 4vw;
}
.qa__content:nth-child(n+2) {
  margin-top: 9.3333333333vw;
}
.qa__content--q {
  display: flex;
  align-items: center;
  gap: 1.0666666667vw;
}
.qa__content--q img {
  height: 24.2666666667vw;
  width: 24.2666666667vw;
}
.qa__content--q p {
  font-size: 4vw;
  font-weight: bold;
  line-height: 1.4666666667;
}
.qa__content--q p span {
  color: #ffc0be;
  font-weight: inherit;
  font-size: inherit;
}
.qa__content--border {
  margin-top: 3.7333333333vw;
  height: 0.5333333333vw;
  background-color: #434343;
  position: relative;
}
.qa__content--border::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-top: 13px solid #434343;
  border-bottom: 0;
  position: absolute;
  bottom: -3.2vw;
  left: 50%;
  transform: translateX(-50%);
}
.qa__content--a {
  margin-top: 5.8666666667vw;
  font-size: 4.2666666667vw;
  line-height: 1.75;
}
.qa__content--a span {
  font-size: 5.3333333333vw;
  font-weight: bold;
  color: #ff82a9;
}
.qa .form__btn {
  margin-top: 7.2vw;
}
.qa .qa__content {
  margin-top: 0;
  padding-bottom: 4.5333333333vw;
}
.qa .qa__content--q {
  gap: 2.1333333333vw;
}
.qa .qa__content--q img {
  width: 18.6666666667vw;
  height: 18.6666666667vw;
}
.qa .qa__content--q p {
  font-size: 4.2666666667vw;
}
.qa .qa__content:nth-of-type(n + 2) {
  padding-top: 2.6666666667vw;
  border-top: 1px dashed #707070;
}

/* ---------------------------- qa -- */
/* -- footer ---------------------------- */
.footer {
  padding: 4.5333333333vw 5.3333333333vw 8vw;
  background-color: #f5f5f5;
}
.footer__logo {
  width: 65.6vw;
  margin: 0 auto;
}
.footer__sns {
  margin-top: 3.2vw;
  display: flex;
  justify-content: center;
  gap: 2.4vw;
}
.footer__sns a {
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  border-radius: 50%;
}
.footer__sns a img {
  height: 100%;
}
.footer p {
  margin-top: 5.3333333333vw;
  font-size: 2.6666666667vw;
  line-height: 1.8;
}
.footer__link {
  margin-top: 3.7333333333vw;
  display: flex;
  gap: 5.0666666667vw;
}
.footer__link a {
  font-size: 2.6666666667vw;
  text-decoration: underline #434343;
}

/* ---------------------------- footer -- */
/* -- office ---------------------------- */
.offices {
  margin-top: 3.2vw;
}
.offices__ttl {
  font-size: 9.6vw;
  font-weight: bold;
  color: #ff82a9;
  text-transform: uppercase;
  text-align: center;
}
.offices__ttl + p {
  font-size: 4.8vw;
  font-weight: bold;
  line-height: 1.4444444444;
  text-align: center;
}
.offices__city {
  margin-top: 0.8vw;
  padding: clamp(10.5px, 4vw, 18px) clamp(14px, 5.3333333333vw, 24px);
  font-size: clamp(11.2px, 4.2666666667vw, 19.2px);
  font-weight: bold;
  background-color: #ffebe7;
}
.offices__list {
  padding: 0 clamp(13.3px, 5.0666666667vw, 22.8px) clamp(14px, 5.3333333333vw, 24px) clamp(14px, 5.3333333333vw, 24px);
  margin-top: clamp(2.1px, 0.8vw, 3.6px);
}
.offices h4 {
  font-size: clamp(10.5px, 4vw, 18px);
  font-weight: 400;
  padding-left: clamp(11.9px, 4.5333333333vw, 20.4px);
  padding: clamp(9.1px, 3.4666666667vw, 15.6px) 0;
  border-bottom: 1px dashed #707070;
  cursor: pointer;
  position: relative;
}
.offices h4::after {
  position: absolute;
  top: 50%;
  right: 3.4666666667vw;
  width: 0;
  height: 0;
  transform: translateY(-50%);
  border-top: clamp(4.2px, 1.6vw, 7.2px) solid transparent;
  border-right: 0;
  border-bottom: clamp(4.2px, 1.6vw, 7.2px) solid transparent;
  border-left: clamp(7.7px, 2.9333333333vw, 13.2px) solid #d0d0d0;
  border-style: solid;
  content: "";
}
.offices h4.open::after {
  transform: rotate(90deg) translateX(calc(-1 * clamp(3.5px, 1.3333333333vw, 6px)));
}
.offices__detail {
  display: none;
}
.offices__detail--inner {
  padding-bottom: clamp(9.1px, 3.4666666667vw, 15.6px);
}
.offices__img {
  margin-top: clamp(18.2px, 6.9333333333vw, 31.2px);
  position: relative;
}
.offices__img .swiper-slide {
  text-align: center;
}
.offices__img img {
  width: clamp(189px, 72vw, 324px);
  height: clamp(129.5px, 49.3333333333vw, 222px);
  border-radius: clamp(7px, 2.6666666667vw, 12px);
}
.offices__txt {
  margin-top: clamp(3.5px, 1.3333333333vw, 6px);
}
.offices__txt h5 {
  margin-top: clamp(8.4px, 3.2vw, 14.4px);
  padding: clamp(4.2px, 1.6vw, 7.2px) 0;
  font-size: clamp(9.8px, 3.7333333333vw, 16.8px);
  font-weight: bold;
  text-align: center;
  background-color: #ffebe7;
  border-radius: clamp(28px, 10.6666666667vw, 48px);
}
.offices__info li dl {
  display: flex;
  align-items: baseline;
  gap: clamp(5.6px, 2.1333333333vw, 9.6px);
}
.offices__info li + li {
  margin-top: clamp(4.9px, 1.8666666667vw, 8.4px);
}
.offices__tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: clamp(58.1px, 22.1333333333vw, 99.6px);
  font-size: clamp(9.8px, 3.7333333333vw, 16.8px);
  font-weight: bold;
  padding: clamp(4.2px, 1.6vw, 7.2px) 0;
  border-radius: clamp(28px, 10.6666666667vw, 48px);
  background-color: #f5f5f5;
}
.offices__appeal {
  margin-top: clamp(4.9px, 1.8666666667vw, 8.4px);
  font-size: clamp(9.8px, 3.7333333333vw, 16.8px);
}

.swiper-pagination-bullets {
  position: static;
  margin-top: clamp(7px, 2.6666666667vw, 12px);
}

.swiper-pagination-bullet {
  width: clamp(8.4px, 3.2vw, 14.4px) !important;
  height: clamp(8.4px, 3.2vw, 14.4px) !important;
  margin-left: clamp(8.4px, 3.2vw, 14.4px) !important;
  margin-right: 0 !important;
  border: 2px solid #bebebe;
  background: transparent !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #d0d0d0 !important;
  border: 2px solid #d0d0d0;
}

.swiper-pagination-bullet:first-child {
  margin: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
  top: clamp(63px, 24vw, 108px);
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  bottom: 0;
  content: "";
  height: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 0;
}

.swiper-button-prev::after {
  border-bottom: clamp(5.6px, 2.1333333333vw, 9.6px) solid transparent;
  border-right: clamp(10.5px, 4vw, 18px) solid #ff82a9;
  border-top: clamp(5.6px, 2.1333333333vw, 9.6px) solid transparent;
}

.swiper-button-next::after {
  border-bottom: clamp(5.6px, 2.1333333333vw, 9.6px) solid transparent;
  border-left: clamp(10.5px, 4vw, 18px) solid #ff82a9;
  border-top: clamp(5.6px, 2.1333333333vw, 9.6px) solid transparent;
}

/* ---------------------------- office -- */
/* -- responsive ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ responsive -- */
@media only screen and (min-width: 768px) {
  /* -- topCatch ---------------------------- */
  .topCatch {
    padding: 0.2857142857vw 18.5714285714vw 0;
    height: 5.2857142857vw;
  }
  .topCatch__txt {
    flex-direction: row;
    align-items: baseline;
  }
  .topCatch__txt::before, .topCatch__txt::after {
    width: 0.2857142857vw;
    top: 60%;
  }
  .topCatch__txt--first {
    margin-right: 0.5714285714vw;
    font-size: 1.5vw;
  }
  .topCatch__txt--second {
    font-size: 2.2857142857vw;
  }
  .topCatch__txt--second span {
    font-size: 2.7142857143vw;
  }
  .topCatch__txt--second span::after {
    height: 0.5714285714vw;
    width: 0.5714285714vw;
    bottom: -0.2142857143vw;
  }
  /* ---------------------------- topCatch -- */
  /* -- fv ---------------------------- */
  .fv {
    height: 44.7142857143vw;
  }
  .fv__wrap {
    margin-right: 10.7142857143vw;
    margin-left: auto;
    width: 64.2857142857vw;
  }
  .fv h1 {
    font-size: 4.0714285714vw;
    text-align: center;
    position: relative;
    line-height: 1.4736842105;
  }
  .fv h1::after {
    font-size: 1.2857142857vw;
    bottom: -0.7142857143vw;
    left: 50%;
    transform: translateX(-50%);
  }
  .fv__catch--wrap {
    margin-top: 0.4285714286vw;
    gap: 1.1428571429vw;
    justify-content: end;
    padding-right: 1.9285714286vw;
  }
  .fv__catch--wrap p {
    font-size: 2.5714285714vw;
  }
  .fv__catch--wrap p span {
    font-size: 3.0714285714vw;
  }
  .fv__catch--wrap p + p {
    filter: drop-shadow(-1px 2px 0 white);
  }
  .fv__feature {
    margin-top: 0.2857142857vw;
    width: 100%;
    padding: 0.5714285714vw 0;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding-right: 2.1428571429vw;
  }
  .fv__feature div p {
    font-size: 1.8571428571vw;
  }
  .fv__feature div img:nth-of-type(1) {
    width: 2.8571428571vw;
    margin-right: 0.6428571429vw;
  }
  .fv__feature div img:nth-of-type(2) {
    width: 2.1428571429vw;
    margin-left: 0.7142857143vw;
  }
  .fv__feature div + p {
    font-size: 1.8571428571vw;
    margin-left: 0.7142857143vw;
  }
  .fv__content {
    margin-top: 0.2857142857vw;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 0 1vw;
  }
  .fv__content--rank {
    width: 19.7857142857vw;
    margin-left: 0;
    margin-right: 0;
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .fv__content--point {
    margin: 0;
    margin-left: auto;
    justify-content: unset;
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .fv__content--point--wrap {
    width: 12.8571428571vw;
    height: 12.8571428571vw;
  }
  .fv__content--point--wrap p {
    margin-top: 2.0714285714vw;
    font-size: 1.3571428571vw;
  }
  .fv__content--point--wrap p + p {
    margin-top: 0.5vw;
    line-height: 1.347826087;
    font-size: 1.6428571429vw;
  }
  .fv__content--pay {
    width: 27.8571428571vw;
    height: 12.8571428571vw;
    margin-top: 2vw;
    padding: 1.0714285714vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    gap: 0.2857142857vw 0.5714285714vw;
    grid-column: 1/2;
    grid-row: 2/3;
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
    margin-left: auto;
  }
  .fv__content--pay--wrap {
    text-align: center;
  }
  .fv__content--pay--wrap:nth-of-type(1) {
    width: 100%;
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .fv__content--pay--wrap:nth-of-type(2) {
    width: 100%;
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .fv__content--pay--wrap:nth-of-type(3) {
    width: 100%;
    display: flex;
    grid-column: 1/3;
    grid-row: 2/3;
    gap: 0.7142857143vw;
    justify-content: center;
  }
  .fv__content--pay--wrap:nth-of-type(3) .fv__content--pay--ttl {
    margin-top: 0.1428571429vw;
    width: 12.1428571429vw;
  }
  .fv__content--pay--wrap:nth-of-type(3) .fv__content--pay--num {
    margin-top: 0;
    font-size: 3.3571428571vw;
  }
  .fv__content--pay--wrap:nth-of-type(3) .fv__content--pay--num .small {
    font-size: 1.4285714286vw;
  }
  .fv__content--pay--ttl {
    height: 3vw;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.6666666667vw;
    font-size: 1.2857142857vw;
  }
  .fv__content--pay--num {
    margin-top: 0.4285714286vw;
    font-size: 3.9285714286vw;
  }
  .fv__content--pay--num .big {
    font-size: 3.2142857143vw;
  }
  .fv__content--pay--num .small {
    font-size: 1.5714285714vw;
    margin-bottom: 0.4285714286vw;
  }
  /* ---------------------------- fv -- */
  /* -- worries ---------------------------- */
  .worries__ttl {
    padding: 1.2142857143vw 0;
    font-size: 1.7142857143vw;
  }
  .worries__ttl::after {
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-top: 35px solid #f5f5f5;
    bottom: -1.4285714286vw;
  }
  .worries__content {
    margin-top: 2.1428571429vw;
    gap: 0.7857142857vw;
  }
  .worries__content img {
    height: 12.8571428571vw;
    width: 12.8571428571vw;
  }
  .worries__txt {
    gap: 1.8571428571vw;
  }
  .worries__txt::after {
    font-size: 1.6428571429vw;
  }
  .worries__txt p {
    font-size: 1.5714285714vw;
    text-decoration-thickness: 0.3571428571vw;
  }
  /* ---------------------------- worries -- */
  /* -- merit ---------------------------- */
  .merit {
    margin-top: 3.5714285714vw;
    padding: 6.8571428571vw 0 1.7857142857vw;
    position: relative;
  }
  .merit__catch {
    gap: 0.8571428571vw;
    position: absolute;
    top: -1.5vw;
    left: 50%;
    transform: translate(-50%);
  }
  .merit__catch p {
    padding: 0 0.2857142857vw;
    font-size: 1.8571428571vw;
  }
  .merit__catch p span {
    font-size: 2.3571428571vw;
  }
  .merit__catch p:first-of-type {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  .merit__catch p:last-of-type {
    line-height: 1.3;
  }
  .merit__wrap {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.1428571429vw;
  }
  .merit__wrap img {
    height: 12.4285714286vw;
    width: 25.5vw;
  }
  .merit__content {
    margin-top: 0;
  }
  .merit__list {
    display: flex;
    justify-content: center;
    gap: 0.3571428571vw;
  }
  .merit__list li {
    height: 2.4285714286vw;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .merit__list li:nth-of-type(1), .merit__list li:nth-of-type(3) {
    width: 8vw;
  }
  .merit__list li:nth-of-type(2) {
    width: 9.6428571429vw;
  }
  .merit__list li:nth-of-type(4) {
    font-size: 1.0714285714vw;
    align-self: center;
  }
  .merit__txt {
    margin-top: 0.5714285714vw;
  }
  .merit__txt p {
    font-size: 2.1428571429vw;
    text-align: left;
  }
  .merit__txt p span {
    font-size: 1.9285714286vw;
  }
  .merit__txt p + p {
    font-size: 2.3571428571vw;
  }
  /* ---------------------------- merit -- */
  /* -- form ---------------------------- */
  .form {
    padding: 1.1428571429vw 1.4285714286vw 1.6428571429vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form img {
    width: 40.7142857143vw;
  }
  .form p {
    margin-top: 0.2142857143vw;
    font-size: 1.1428571429vw;
  }
  .form__btn {
    margin-top: 0.7857142857vw;
  }
  .form__btn button {
    width: 46.8571428571vw;
    height: 6.0714285714vw;
    border-radius: 0.7142857143vw;
    transition: 0.3s;
  }
  .form__btn button a {
    justify-content: center;
    gap: 0.6428571429vw;
    font-size: 2.2142857143vw;
    transition: 0.3s;
  }
  .form__btn button a::after {
    border-top: 0.5714285714vw solid transparent;
    border-bottom: 0.5714285714vw solid transparent;
    border-left: 1.0714285714vw solid #434343;
    right: 1.2142857143vw;
    transition: 0.3s;
  }
  .form__btn button a img {
    width: 3.5vw;
    transition: 0.3s;
  }
  .form__btn button:hover {
    border: 3px solid #fff;
    background-color: #ff82a9;
  }
  .form__btn button:hover a {
    color: #fff;
    opacity: 1;
  }
  .form__btn button:hover a::after {
    border-left: 1.0714285714vw solid #fff;
  }
  .form__btn.fixed_pc {
    margin-top: 0;
    position: fixed;
    top: 0;
    right: 0;
    height: 27.2142857143vw;
    width: 6vw;
    opacity: 1;
    transition: 0.3s;
  }
  .form__btn.fixed_pc.hidden {
    opacity: 0;
    visibility: hidden;
  }
  .form__btn.fixed_pc button {
    border-radius: 0.7142857143vw 0 0 0.7142857143vw;
    width: 100%;
    height: 100%;
  }
  .form__btn.fixed_pc button a {
    padding: 0;
    width: 100%;
    gap: 0.6428571429vw;
    font-size: 1.5714285714vw;
    writing-mode: vertical-rl;
    justify-content: start;
    padding-top: 3.0714285714vw;
  }
  .form__btn.fixed_pc button a::after {
    border-top: 0.9285714286vw solid #ff82a9;
    border-bottom: 0;
    border-left: 0.5vw solid transparent;
    border-right: 0.5vw solid transparent;
    top: unset;
    right: unset;
    bottom: 1.4285714286vw;
    transform: translateX(-50%);
    left: 51%;
  }
  .form__btn.fixed_pc button a img {
    width: 2.5714285714vw;
    height: auto;
  }
  .form__btn.fixed_pc button:hover a::after {
    border-top: 0.9285714286vw solid #fff;
  }
  .form__link {
    margin-top: 0.3571428571vw;
    font-size: 1.0714285714vw;
    transition: 0.3s;
  }
  .form__link:hover {
    opacity: 0.7;
  }
  /* ---------------------------- form -- */
  /* -- point ---------------------------- */
  .point {
    margin-top: 1.7142857143vw;
    margin-bottom: 2.8571428571vw;
  }
  .point__ttl {
    font-size: 3.2857142857vw;
    line-height: 1.3043478261;
  }
  .point__catch {
    font-size: 1.7142857143vw;
  }
  .point__catch span {
    font-size: 2.8571428571vw;
  }
  .point__wrap {
    margin-top: 3.2142857143vw;
    position: relative;
  }
  .point__wrap:nth-of-type(2) {
    margin-top: 8.2142857143vw;
  }
  .point__wrap:nth-of-type(3) {
    margin-top: 4.5vw;
  }
  .point__wrap--imgTtl {
    top: -2.7857142857vw;
    left: 10.7142857143vw;
    width: 12.2857142857vw;
  }
  .point__wrap--ttl {
    padding: 1vw 0 1.2142857143vw;
    font-size: 2.2857142857vw;
  }
  .point__wrap--ttl span {
    font-size: 2vw;
  }
  .point__wrap.bg_gray {
    background-color: #f5f5f5;
  }
  .point__inner {
    margin: 2.5vw 10.7142857143vw 0;
    display: flex;
    justify-content: center;
    gap: 1.6428571429vw;
  }
  .point__inner img {
    height: 14.7142857143vw;
    width: 38.8571428571vw;
  }
  .point__inner.reverse {
    flex-direction: row-reverse;
  }
  .point__txt {
    margin: 0;
  }
  .point__txt p {
    font-size: 1.2857142857vw;
    margin-top: 0;
    line-height: 1.7777777778;
  }
  .point__pay {
    margin: 1.9285714286vw 10.7142857143vw 0;
    padding: 1.6428571429vw 6.7857142857vw;
    border: 1px solid #707070;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  .point__pay--note {
    font-size: 1.2857142857vw;
    text-align: center;
    margin-top: 0;
    line-height: 1.7777777778;
  }
  .point__pay--content {
    margin-top: 1.7857142857vw;
    display: flex;
    justify-content: space-between;
    gap: 2.2142857143vw;
  }
  .point__pay--wrap {
    width: 100%;
    margin-top: 0;
    gap: 2.2142857143vw;
  }
  .point__pay--wrap:nth-of-type(n + 2) {
    margin-top: 0;
  }
  .point__pay--ttl {
    height: 3vw;
    font-size: 1.2857142857vw;
  }
  .point__pay--detail {
    gap: 1.4285714286vw;
  }
  .point__pay--txt {
    margin-top: 1.0714285714vw;
  }
  .point__pay--txt p:first-of-type {
    font-size: 1.1428571429vw;
    padding-bottom: 0.7142857143vw;
  }
  .point__pay--txt p:last-of-type {
    line-height: 1.3333333333;
    font-size: 4.2857142857vw;
  }
  .point__pay--txt p:last-of-type span {
    font-size: 1.8571428571vw;
  }
  .point__demand {
    width: 44.2857142857vw;
    margin: 0 auto;
    margin-top: 1.7142857143vw;
  }
  .point__demand--ttl {
    padding: 0.7142857143vw 0;
    font-size: 1.2857142857vw;
  }
  .point__demand--ttl::before, .point__demand--ttl::after {
    height: 0.3571428571vw;
  }
  .point__demand--list {
    margin-top: 1.2857142857vw;
    display: flex;
    justify-content: space-between;
  }
  .point__demand--list li {
    padding-left: 1.3571428571vw;
    font-size: 1.1428571429vw;
  }
  .point__demand--list li::before {
    height: 0.5714285714vw;
    width: 0.5714285714vw;
  }
  .point__ease {
    margin: 2vw 10.7142857143vw 0;
  }
  .point__ease--ttl {
    font-size: 1.2857142857vw;
    height: 3.2857142857vw;
    border-radius: 3.5714285714vw;
  }
  .point__ease--ttl::after {
    border-right: 0.9285714286vw solid transparent;
    border-left: 0.9285714286vw solid transparent;
    border-top: 1.8571428571vw solid #ff82a9;
    bottom: -0.8571428571vw;
  }
  .point__ease--content {
    margin: 1.6428571429vw 1.8571428571vw 0;
    padding-bottom: 2.4285714286vw;
    display: flex;
  }
  .point__ease--content--wrap {
    margin-bottom: 0;
    padding-right: 0.7142857143vw;
  }
  .point__ease--content--wrap:nth-of-type(1) {
    width: 23.9285714286vw;
  }
  .point__ease--content--wrap:nth-of-type(n + 2) {
    width: 25.3571428571vw;
    padding-top: 0;
    border-left: 1px dashed #707070;
    padding-left: 1.4285714286vw;
    border-top: unset;
  }
  .point__ease--content--ttl {
    gap: 1vw;
    font-size: 1.2857142857vw;
  }
  .point__ease--content--ttl img {
    height: 4.5714285714vw;
    width: 4.5714285714vw;
  }
  .point__ease--content--txt {
    font-size: 1.1428571429vw;
    line-height: 1.75;
  }
  /* ---------------------------- point -- */
  /* -- anxiety ---------------------------- */
  .anxiety {
    margin: 1.6428571429vw 10.7142857143vw 2.5vw;
  }
  .anxiety__ttl {
    font-size: 1.7142857143vw;
  }
  .anxiety__wrap {
    margin-top: 1.6428571429vw;
    display: flex;
    gap: 3.2142857143vw;
  }
  .anxiety .qa__content {
    width: 33.3333333333%;
  }
  .anxiety .qa__content--q {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    text-align: center;
    white-space: nowrap;
  }
  /* ---------------------------- anxiety -- */
  /* -- works ---------------------------- */
  .works {
    padding: 1.9285714286vw 0 2.7142857143vw;
  }
  .works__ttl {
    font-size: 3.2857142857vw;
  }
  .works__catch {
    font-size: 1.7142857143vw;
    line-height: 0.8;
  }
  .works__catch span {
    font-size: 2.8571428571vw;
  }
  .works__style {
    margin: 2.3571428571vw 1.4285714286vw 0;
    justify-content: center;
    gap: 1.9285714286vw;
  }
  .works__style--wrap {
    width: 17.9285714286vw;
    height: 7.3571428571vw;
    border-radius: 0.7142857143vw;
  }
  .works__style--wrap.office img {
    width: 1.9285714286vw;
  }
  .works__style--wrap.home img {
    width: 2.9285714286vw;
  }
  .works__style--wrap p {
    margin-top: 0.7857142857vw;
    font-size: 1.1428571429vw;
    line-height: 1.25;
  }
  .works__style--rate {
    font-size: 5vw;
  }
  .works__style--rate span {
    font-size: 2vw;
  }
  .works__img {
    background: url("../img/works.jpg") no-repeat;
    background-size: cover;
    background-position: center 35%;
    height: 19.7142857143vw;
  }
  .works__img--bubble {
    width: 12.3571428571vw;
  }
  .works__img--bubble.office {
    bottom: 1.5vw;
    left: 21vw;
  }
  .works__img--bubble.home {
    top: 1.7142857143vw;
    right: 22.5vw;
  }
  .works__kinds {
    margin-top: 1.7142857143vw;
  }
  .works__kinds--ttl {
    font-size: 1.7142857143vw;
  }
  .works__kinds--wrap {
    margin-top: 3.4285714286vw;
    padding: 5.4285714286vw 1.2857142857vw 1.4285714286vw 2.7142857143vw;
  }
  .works__kinds--wrap:nth-of-type(odd) {
    margin-right: 10.7142857143vw;
    border-radius: 0 1.4285714286vw 1.4285714286vw 0;
  }
  .works__kinds--wrap:nth-of-type(odd) .works__kinds--name {
    left: calc(50% - 9.4285714286vw);
  }
  .works__kinds--wrap:nth-of-type(odd) .works__kinds--txt {
    padding-left: 1.4285714286vw;
  }
  .works__kinds--wrap:nth-of-type(odd) .works__kinds--detail {
    padding-left: 10.7142857143vw;
    margin-left: 10.7142857143vw;
  }
  .works__kinds--wrap:nth-of-type(even) {
    margin-left: 10.7142857143vw;
    border-radius: 1.4285714286vw 0 0 1.4285714286vw;
  }
  .works__kinds--wrap:nth-of-type(even) .works__kinds--inner {
    flex-direction: row-reverse;
  }
  .works__kinds--wrap:nth-of-type(even) .works__kinds--name {
    right: calc(50% - 10vw);
  }
  .works__kinds--wrap:nth-of-type(even) .works__kinds--txt {
    padding-right: 1.4285714286vw;
  }
  .works__kinds--wrap:nth-of-type(even) .works__kinds--detail {
    padding-right: 10.7142857143vw;
    margin-left: 8vw;
  }
  .works__kinds--wrap:nth-of-type(n + 2) {
    margin-top: 5.2857142857vw;
  }
  .works__kinds--name {
    height: 5.3571428571vw;
    width: 30.2142857143vw;
    font-size: 2.2857142857vw;
    top: -2.6428571429vw;
  }
  .works__kinds--inner {
    display: flex;
    align-items: center;
  }
  .works__kinds--inner img {
    height: 11.5vw;
    width: 47vw;
  }
  .works__kinds--txt {
    margin-top: 0;
    font-size: 1.2857142857vw;
    line-height: 1.5555555556;
  }
  .works__kinds--detail {
    margin: 1.5714285714vw 0 0;
    width: 66.4285714286vw;
    display: grid;
    justify-content: center;
  }
  .works__kinds--detail.normal {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.8571428571vw;
  }
  .works__kinds--detail.normal .works__kinds--detail--sum {
    text-align: center;
  }
  .works__kinds--detail.special {
    gap: 0.7857142857vw 1.7857142857vw;
  }
  .works__kinds--detail.special .works__kinds--detail--pay,
  .works__kinds--detail.special .works__kinds--detail--sum {
    font-size: 1.1428571429vw;
  }
  .works__kinds--detail--pay {
    height: 2.7142857143vw;
    border-radius: 1.4285714286vw;
    font-size: 1.1428571429vw;
  }
  .works__kinds--detail--pay span {
    font-size: 1.7142857143vw;
  }
  .works__kinds--detail--pay.office {
    background-color: #ffc0be;
  }
  .works__kinds--detail--pay.home {
    background-color: #ff82a9;
  }
  .works__kinds--detail--sum {
    font-size: 1.1428571429vw;
  }
  .works__kinds--detail--sum span {
    font-size: 2vw;
  }
  .works__kinds--note {
    margin-top: 0.7857142857vw;
    margin-left: 22.7142857143vw;
    font-size: 0.8571428571vw;
  }
  /* ---------------------------- works -- */
  /* -- interview ---------------------------- */
  .interview {
    margin-top: 2.7142857143vw;
  }
  .interview__ttl {
    font-size: 3.2857142857vw;
    line-height: 1.4782608696;
  }
  .interview__ttl + p {
    font-size: 1.7142857143vw;
    line-height: 1.5333333333;
  }
  .interview__catch {
    font-size: 2.8571428571vw;
    line-height: 0.8695652174;
  }
  .interview__content {
    margin: 5vw 10.7142857143vw 4.2857142857vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.9285714286vw;
  }
  .interview__content--model {
    margin: 0 2.1428571429vw;
  }
  .interview__content--model--info {
    font-size: 1.1428571429vw;
    line-height: 1.375;
  }
  .interview__content--model--detail {
    margin-top: 1.4285714286vw;
    font-size: 1.7142857143vw;
    line-height: 1.3333333333;
  }
  .interview__content--model--img {
    width: 17.2857142857vw;
    height: 17.2857142857vw;
  }
  .interview__content--txt {
    margin-top: 3.2857142857vw;
    padding: 3.6428571429vw 1.9285714286vw 0.7142857143vw;
    font-size: 1.2857142857vw;
    line-height: 1.7777777778;
  }
  .interview__content--txt::before {
    top: -2.0714285714vw;
    left: unset;
    transform: unset;
    border-right: 1.7142857143vw solid transparent;
    border-left: 1.7142857143vw solid transparent;
    border-bottom: 3vw solid #f5f5f5;
  }
  .interview__content--txt span {
    font-size: inherit;
    font-weight: bold;
    color: #ff82a9;
  }
  .interview__wrap {
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .interview__wrap:nth-of-type(1) .interview__content--txt::before {
    right: 8.9285714286vw;
  }
  .interview__wrap:nth-of-type(2) {
    margin-top: 0;
  }
  .interview__wrap:nth-of-type(2) .interview__content--txt::before {
    left: 8.9285714286vw;
  }
  /* ---------------------------- interview -- */
  /* -- qa ---------------------------- */
  .qa {
    margin: 2.7142857143vw 10.7142857143vw 5.4285714286vw;
  }
  .qa__ttl {
    text-align: center;
  }
  .qa__ttl h2 {
    font-size: 3.2857142857vw;
  }
  .qa__ttl p {
    font-size: 1.7142857143vw;
  }
  .qa__wrap {
    margin-top: 2.7142857143vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.2142857143vw;
  }
  .qa__content:nth-child(n+2) {
    margin-top: 0;
  }
  .qa__content--q {
    gap: 0.2857142857vw;
  }
  .qa__content--q img {
    height: 6.5vw;
    width: 6.5vw;
  }
  .qa__content--q p {
    font-size: 1.2857142857vw;
    line-height: 1.5;
  }
  .qa__content--border {
    margin-top: 0;
    height: 0.1428571429vw;
  }
  .qa__content--border::after {
    border-right: 0.5vw solid transparent;
    border-left: 0.5vw solid transparent;
    border-top: 0.9285714286vw solid #434343;
    bottom: -0.8571428571vw;
  }
  .qa__content--a {
    margin-top: 1.5714285714vw;
    font-size: 1.1428571429vw;
  }
  .qa__content--a span {
    font-size: 1.4285714286vw;
  }
  .qa .form__btn {
    margin-top: 7.2vw;
  }
  .qa .qa__content--border {
    margin-top: 1.1428571429vw;
  }
  .qa .qa__wrap {
    gap: 2.8571428571vw 3.2142857143vw;
  }
  .qa .qa__content {
    padding-bottom: 0;
    display: grid;
    grid-template-rows: 6.9444444444vw auto 1fr;
  }
  .qa .qa__content--q {
    gap: 0.2857142857vw;
  }
  .qa .qa__content--q img {
    width: 6.5vw;
    height: 6.5vw;
  }
  .qa .qa__content--q p {
    font-size: 1.2857142857vw;
  }
  .qa .qa__content:nth-of-type(n + 2) {
    padding-top: 0;
    border-top: none;
  }
  /* ---------------------------- qa -- */
  /* -- footer ---------------------------- */
  .footer {
    padding: 1.2142857143vw 1.4285714286vw 1.7857142857vw;
  }
  .footer__logo {
    width: 17.5714285714vw;
  }
  .footer__sns {
    margin-top: 1.1428571429vw;
    gap: 0.8571428571vw;
  }
  .footer__sns a {
    width: 2.4285714286vw;
    height: 2.4285714286vw;
  }
  .footer p {
    margin-top: 0.8571428571vw;
    font-size: 0.7142857143vw;
    text-align: center;
  }
  .footer__link {
    margin-top: 0;
    justify-content: center;
    gap: 1.3571428571vw;
  }
  .footer__link a {
    font-size: 0.7142857143vw;
  }
  /* ---------------------------- footer -- */
  /* -- office ---------------------------- */
  .offices {
    padding: 0 clamp(105px, 10.7142857143vw, 180px);
    margin-top: 1.7857142857vw;
  }
  .offices__ttl {
    font-size: 3.2857142857vw;
  }
  .offices__ttl + p {
    font-size: 1.7142857143vw;
  }
  .offices__city {
    margin-top: 1.0714285714vw;
    padding: clamp(10.5px, 1.0714285714vw, 18px) clamp(11.9px, 1.2142857143vw, 20.4px);
    font-size: clamp(11.2px, 1.1428571429vw, 19.2px);
  }
  .offices__list {
    padding: 0 0 clamp(14px, 1.4285714286vw, 24px) 0;
    margin-top: clamp(2.1px, 0.2142857143vw, 3.6px);
  }
  .offices h4 {
    font-size: clamp(10.5px, 1.0714285714vw, 18px);
    padding-left: clamp(11.9px, 1.2142857143vw, 20.4px);
    padding: clamp(9.1px, 0.9285714286vw, 15.6px) 0;
  }
  .offices h4::after {
    right: 1.4285714286vw;
    border-top: clamp(4.2px, 0.4285714286vw, 7.2px) solid transparent;
    border-bottom: clamp(4.2px, 0.4285714286vw, 7.2px) solid transparent;
    border-left: clamp(7.7px, 0.7857142857vw, 13.2px) solid #d0d0d0;
  }
  .offices h4.open::after {
    transform: rotate(90deg) translateX(calc(-1 * clamp(3.5px, 0.3571428571vw, 6px)));
  }
  .offices__detail--inner {
    display: grid;
    margin-top: clamp(17.5px, 1.7857142857vw, 30px);
    grid-template-columns: clamp(238px, 24.2857142857vw, 408px) 1fr;
    gap: clamp(31.5px, 3.2142857143vw, 54px);
    padding-bottom: clamp(9.1px, 0.9285714286vw, 15.6px);
  }
  .offices__img {
    margin-top: 0;
  }
  .offices__img img {
    width: clamp(189px, 19.2857142857vw, 324px);
    height: clamp(129.5px, 13.2142857143vw, 222px);
    border-radius: clamp(7px, 0.7142857143vw, 12px);
  }
  .offices__txt {
    margin-top: 0;
  }
  .offices__txt h5 {
    margin-top: clamp(14px, 1.4285714286vw, 24px);
    padding: clamp(4.2px, 0.4285714286vw, 7.2px) 0;
    font-size: clamp(9.8px, 1vw, 16.8px);
    border-radius: clamp(28px, 2.8571428571vw, 48px);
  }
  .offices__info {
    display: flex;
    gap: clamp(21px, 2.1428571429vw, 36px);
  }
  .offices__info dl {
    gap: clamp(5.6px, 0.5714285714vw, 9.6px);
  }
  .offices__info li + li {
    margin-top: 0;
  }
  .offices__tag {
    min-width: clamp(58.1px, 5.9285714286vw, 99.6px);
    font-size: clamp(9.8px, 1vw, 16.8px);
    padding: clamp(4.2px, 0.4285714286vw, 7.2px) 0;
    border-radius: clamp(28px, 2.8571428571vw, 48px);
  }
  .offices__appeal {
    margin-top: clamp(7.7px, 0.7857142857vw, 13.2px);
    font-size: clamp(9.8px, 1vw, 16.8px);
  }
  .swiper-pagination-bullets {
    position: static;
    margin-top: clamp(7px, 0.7142857143vw, 12px);
  }
  .swiper-pagination-bullet {
    width: clamp(8.4px, 0.8571428571vw, 14.4px) !important;
    height: clamp(8.4px, 0.8571428571vw, 14.4px) !important;
    margin-left: clamp(8.4px, 0.8571428571vw, 14.4px) !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: clamp(63px, 6.4285714286vw, 108px);
  }
  .swiper-button-prev::after {
    border-bottom: clamp(5.6px, 0.5714285714vw, 9.6px) solid transparent;
    border-right: clamp(10.5px, 1.0714285714vw, 18px) solid #ff82a9;
    border-top: clamp(5.6px, 0.5714285714vw, 9.6px) solid transparent;
  }
  .swiper-button-next::after {
    border-bottom: clamp(5.6px, 0.5714285714vw, 9.6px) solid transparent;
    border-left: clamp(10.5px, 1.0714285714vw, 18px) solid #ff82a9;
    border-top: clamp(5.6px, 0.5714285714vw, 9.6px) solid transparent;
  }
  /* ---------------------------- office -- */
}